<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                    <div class="titulo">
                            Cerrar pedido.
                    </div>
                    <div class="leyendaconfirmar">
                            Esta acción te re-direcciona al cierre del pedido. Le informamos que no va a poder agregar mas productos a este pedido o regresar a cambiar los turnos una vez usted confirme esta accion. ¿Deseas continuar con el cierre del pedido?

                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton" (click)="cancelar()">Cancelar</button>
                            <button class="buttonpc redbutton spacerRed" (click)="cerrarPedido()" >Aceptar</button>
                    </div>
            </div>
           


    </modal-default-mmaxapp>
</div>