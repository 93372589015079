import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mis-datos-mmax',
  templateUrl: './misdatosmmax.component.html',
  styleUrls: ['./misdatosmmax.component.scss']
})
export class MisdatosmmaxComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.whatsappsrc = '../../assets/icons/whatsapp-logo.svg';
    this.correosrc = '../../assets/icons/mail.svg';
    this.serv.getUserProvider().subscribe(data => {
      this.user = data;
    })
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

  }
  whatsappsrc: string;
  correosrc: string;
  user: Usuario
}
