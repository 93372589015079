<modal-open-point id="modal-completar-pago">
    <div normal>
        <modal-completar-pago></modal-completar-pago>
    </div>
</modal-open-point>


<div *ngIf="modo==='normal'">
    <div class="container">
        <div class="productos">
            <div class="productoslabel">
                Productos ({{total}})
            </div>
            <div class="listaprod">
                <lista-productos-view [articulosIn]="articulosconcantidad" [modelo]="'postcheckout'">
                </lista-productos-view>
            </div>
        </div>

        <div class="checkoutcontainer">

            <div class="preMedios" [ngStyle]="{'display': displayPreMedios}">
                <div class="productoslabel">
                    Desea pagar ahora el pedido?
                    Si elige que NO, se tomara como un Pago Diferido, el cual se debera abonar o en la sucursal cuando
                    se retire o si se dispone de envios, cuando llegue el mismo.
                </div>
                <div class="infobox">
                    <div class="infofix">
                        <div class="infoflex">
                            <div class="info">
                                <div (click)="siPagar()" class="roundedOne">
                                    <input type="checkbox" />
                                    <label></label>
                                </div>
                            </div>
                            <div class="info">
                                Si
                            </div>
                        </div>
                        <div class="infoflex">
                            <div class="info">
                                <div (click)="noPagar()" class="roundedOne">
                                    <input type="checkbox" />
                                    <label></label>
                                </div>
                            </div>
                            <div class="info">
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mediosPago" [ngStyle]="{'display': displayMedios}">
                <div *ngIf="mysmedios.length>0; else nomedios">
                    <div class="productoslabel">
                        Forma de Pago
                    </div>
                    <div class="infobox">
                        <!--
                <div (click)="activarMercadoPago()" class="infofix pagomercado">
                    <div class="info">
                        <div class="roundedOne ">
                            <input   [id]="'mercadopago'" type="checkbox"/>
                            <label [for]="'mercadopago'"></label>
                       </div>
                    </div>
        
                    <div class="info">
                        Mercado Pago
                    </div>
                    
                </div>-->
                        <div *ngFor="let medio of mysmedios" (click)="activarMediodepago(medio)" class="infofix ">
                            <div class="infoflex">
                                <div class="info">
                                    <div class="roundedOne">
                                        <input [checked]="medio.selecionado" [id]="medio.id" type="checkbox" />
                                        <label [for]="medio.id"></label>
                                    </div>
                                </div>

                                <div class="info">
                                    {{medio.nombre}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #nomedios>
                    <div class="checkoutcontainer">
                        <div class="productoslabel">
                            Forma de Pago
                        </div>
                        <div class="infobox">
                            <div class="infofix">
                                <div class="info">
                                    No dispone de medios asociados a su cuenta para abonar.
                                </div>
                                <div class="info">
                                    <button (click)="volverAtras()" class="buttonpc whibutton noselect">Anterior</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-template>

                <div *ngIf="mymedios!=undefined">
                    <div *ngIf="pagosActuales.length>0; else noPlanes">
                        <div class="productoslabel">
                            Planes de Pago {{mymedios.nombre}}
                            <div *ngIf="error!=undefined" class=" info tooltip">
                                <div class="errorus">
                                    {{error}}
                                    <div class="atras">
                                        <button (click)="volverAtras()" class="buttonpc whibutton noselect">Anterior</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div class="infobox">
                            <div *ngFor="let plan of pagosActuales" (click)="activarPlan(plan)" class="infofix">
                                <div class="infoflex">
                                    <div class="info">
                                        <div class="roundedOne">
                                            <input [checked]="plan.seleccionado" [id]="plan.id" type="checkbox" />
                                            <label [for]="plan.id"></label>
                                        </div>
                                    </div>

                                    <div class="info">
                                        {{plan.cuotas}}x {{plan.montoCuota | currency}} - {{plan.nombre}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <ng-template #noPlanes>
                        <div class="productoslabel">
                            Planes de Pago {{mymedios.nombre}}
                        </div>
                        <div class="infobox">
                            <div class="infofix">
                                <div class="info">
                                    No dispone de planes asociados al metodo de pago selecionado.
                                </div>
                                <div class="info">
                                    <button (click)="volverAtras()" class="buttonpc whibutton noselect">Anterior</button>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </div>

            </div>


            <div class="botoneraPC">
                <!-- <div class="anterior">
                    <button  (click)="volverAtras();cancelarPedidoWeb()" class="buttonpc whibutton noselect">Anterior</button>
                </div> -->
                <div class="sutotalesbox">
                    <div class="renglon">
                        <div class="infoblue subtotaltext">
                            Subtotal
                        </div>
                        <div class="infoblue textcomun eltotal centertext">
                            {{totales | currency}}
                        </div>
                    </div>
                    <div class="renglon">
                        <div class="infoblue subtotaltext">

                        </div>
                        <div class="infoblue textcomun centertext">
                            {{totales | currency}}
                        </div>
                    </div>
                    <div class="renglon">
                        <div class="infoblue textcomun">
                            Costo de Preparación
                        </div>
                        <div class="infoblue textcomun centertext">
                            {{0 | currency}}
                        </div>
                    </div>
                    <div class="renglon">
                        <div class="infoblue textcomun">
                            Descuentos Aplicados
                        </div>
                        <div class="infoblue textcomun centertext">
                            {{0 | currency}}
                        </div>
                    </div>
                    <div class="renglon">
                        <div class="infoblue subtotaltext">
                            TOTAL:
                        </div>
                        <button [disabled]="(myplan==undefined && mymedios==undefined)"
                            (click)="pagarYcontinuar()" onclick="window.scrollTo(0,0)" class="buttonpc blubutton noselect">
                            Pagar {{totales | currency}}
                        </button>

                    </div>

                </div>
            </div>
        </div>

    </div>






    <mmax-helpfootbar></mmax-helpfootbar>
</div>