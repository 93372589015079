import { Component, OnInit, Input } from '@angular/core';
import { GenericoSlideAnim } from '../etc/generico-animation'
import { MarianomGeneral } from '../marianom-general.service';
import { MenuMmaxInterface } from '../../app-shared/etc/mmax-menu.interface';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CategoriasWebMenu } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'categorias-generico',
  templateUrl: './categorias-generico.component.html',
  styleUrls: ['./categorias-generico.component.scss'],
  animations: [GenericoSlideAnim]

})
export class CategoriasGenericoComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() login: string;
  @Input() myData: string;
  menues: { nombre: string; url: string; urlmobile: string; icourl: string; }[];

  constructor(private serv: MarianomGeneral, private route: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.login = this.login || 'none';
    this.myData = this.login || 'none';
    this.swicher = false;

    this.menues = [
      {
        nombre: 'Mis Datos',
        url: 'app/misdatos',
        urlmobile: '/app-mobile/app/account',
        icourl: '../../assets/icons/credit-card-outline-white.svg'
      }
    ];

    this.isLogued = this.serv.isloged()

  }

  ngOnInit(): void {
    if (this.isLogued === false) {
      this.myData = 'none';
      this.login = 'block';
    } else {
      this.myData = 'flex';
      this.login = 'none';
    }
  }

  openLogin() {
    this.modal.open('login-modal-1')
  }

  cerrarSesion() {
    this.serv.salirylimpiar();
    this.serv.cerrarUI();

  }

  cerrarCateg(event: CategoriasWebMenu) {
    for (let i = 0; i < this.categorias.length; i++) {
      const element = this.categorias[i];
      element.desplegado = false;
    }
    console.log("EVENT: ", event.desplegado);

    if (event.desplegado === true) {
      event.desplegado = false;
    }
    if (event.desplegado === false) {
      event.desplegado = true;
    }
  }
  get categorias() {
    return this.serv.getCategorias();
  }
  abrirCerrar() {

    //Cambiar (desactivar) el scroll de la  pagina al abrir modales
    // let body = document.getElementsByClassName("body").overflow = "hidden";

    if (this.serv.isloged() === false) {
      this.myData = 'none';
      this.login = 'block';
    } else {
      this.myData = 'flex';
      this.login = 'none';
    }

    this.serv.desplegaryCerrar(!this.desplegado);
  }
  getColor() {
    if (this.swicher) {
      this.swicher = !this.swicher;
      return 'rgb(214 220 246 / 13%)';
    }
    else {
      this.swicher = !this.swicher;
      return '#FFFFFFFF';
    }


  }

  gotoURL(url: string) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.gotoURL(url, this.route);
    this.abrirCerrar();

  }

  gotoBusqueda(url: string) {/// METODO A CAMBIAR, NAVIGATOR
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.gotoURL(url, this.route);

  }

  menuclick(menus: MenuMmaxInterface) {
    this.serv.activatethis(menus);

    console.log(menus);

    console.log(this.menus);
    this.serv.gotoURL(menus.ruta, this.route);

  }
  iraLand() {
    this.serv.navigator.goLandPage(this.route);
    this.serv.cerrarUI();
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }
  get desplegado(): boolean {
    return this.serv.desplegadocat;
  }


  swicher: boolean;
  isLogued: boolean;

}
