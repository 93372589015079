<div *ngIf="modo==='normal'">


    <!-- <div class="container-carga" [ngStyle]="{'display': loader}">
        <div class="carga"></div>
    </div> -->

    <div class="seccion">
        <div class="nombre">
            <div class="ico">

            </div>
            <div class="label">
                <div class="ttile">
                    Historial
                </div>
                <div class="prepaf">
                    Estas son todas las compras que has realizado en nuestra plataforma online.
                </div>
            </div>


        </div>
    </div>
    <section class="histlist">
        <div class="container-carga" [ngStyle]="{'display': loader}">
            <div class="carga"></div>
        </div>
        <div class="noCompras" [ngStyle]="{'display': noCompras}">Si la carga no termina es porque usted no posee
            compras realizadas previamente o la pagina esta caida...</div>
        <historial-generic-wiew *ngFor="let historia of historial" (onRepat)="recompra($event)"
            (onClick)="verDetalle($event)" [jistoria]="historia"></historial-generic-wiew>

    </section>

</div>