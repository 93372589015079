import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ModalAgregarProductoComponent } from '../modales/modal-agregar-producto/modal-agregar-producto.component';

@Component({
  selector: 'mmax-helpfootbar',
  templateUrl: './helpfootbar.component.html',
  styleUrls: ['./helpfootbar.component.scss']
})
export class HelpfootbarComponent implements OnInit {
  @Input('mode') modo: string;
  constructor(private modal: ModalService) {
    this.modo = this.modo || 'normal'
    this.logoformobile = '../../assets/logo/logo.png';
    this.soporteimg = "../../assets/misc/splash-sup.png";
  }

  ngOnInit(): void {
    this.modo = this.modo || 'normal'
  }

  arrepentimiento(){
    this.modal.open('modal-no-implemented')
  }
  logoformobile: string;
  soporteimg: string;
}
