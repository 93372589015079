import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'modal-monto-minimo',
  templateUrl: './modal-monto-minimo.component.html',
  styleUrls: ['./modal-monto-minimo.component.scss']
})
export class ModalMontoMinimoComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar(){
    this.modal.close("modal-monto-minimo");
  }

  get montoMinimo(){
    return this.serv.getMontoMinimo();
  }

}
