import { OfertasEcommerce } from "../interfaces/ofertas-ecommerce.interface";
import { TagsEcommerce } from "../interfaces/tags-eccomerce.interface";
import { PromocionesEccomerce } from "./promociones-class";

export class DiferenciaPromo extends PromocionesEccomerce{
   deOferta: OfertasEcommerce;
   isdeoferta: boolean;

    setTag(tag: TagsEcommerce) {
        throw new Error("Method not implemented.");
    }
    getTag(): TagsEcommerce[] {
        throw new Error("Method not implemented.");
    }
    
    
    getCantidad() {
        return this.counter;
    }
    
    getRemplazos() {
        throw new Error("Method not implemented.");
    }
    
    
    isdeOferta(): boolean {
        return false;
    }
    
    
    
    setdeOferta(ofertas: OfertasEcommerce) {
        throw new Error("Method not implemented.");
    }
    
    getPromosActivas(): string[] {
        return [];
    }
    getDescontadoTotal(): number {
        return this.monto*this.counter;
    }
    getDescontado(): number {
        return this.monto;
    }
    operacionesExtras() {
        console.log('se ejecuto  DiferenciaPromo');
        
    }
    preconPromo(precio: number,cantidad:number,modificacionActual:number) {
        return this.monto;
    }
    
    setMyBlob(body: Blob) {
        this.myblob=body;
    }
    getMyBlob(): Blob {
        return this.myblob;
    }
    setRemplazo(numero: number) {
        throw new Error("Method not implemented.");
    }
    
    getPrecioPartido(): number {
        throw new Error("Method not implemented.");
    }

    getOtrosPrecios(): string {
        throw new Error("Method not implemented.");
    }
    getRemplazo() {
        throw new Error("Method not implemented.");
    }
    
    setModificacionPrecio(arg0: number) {
        throw new Error("Method not implemented.");
    }
    setPromo(k: PromocionesEccomerce) {
        throw new Error("Method not implemented.");
    }
    getPrecioNormal() {
        return 0
    }
    getPrecioMayorista(){
        return 0;
    }
    getPrecioAmostrar() {
        return 0
    }
    getPresentacion() {
        return ' '
    }
    agregarCantidad(numero: number) {
        console.log(' se agrego diferencia promo');
        
    }
    setCantidad(arg0: number) {
        console.log(' se seteo diferencia promo');
    }
    getPrecioCalculado(): number {
        return 0;
    }
   
    getPrecioOferta(): number {
        return this.getPrecioOferta();
    }
   
    getPrecio() {
        return this.monto;
    }
    getImgDownload(): boolean {
        return this.descargo;
     }
     setImgDownload(valor: boolean) {
         this.descargo=valor;
     }
   
   hacerCalculos() {
        //console.log(this.getPrecio());
        
        return  (this.getCantidad() * this.getPrecio())*1

    }
    private myblob:Blob;
    
    
}