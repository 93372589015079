<section>
    <div class="formsup">

        <div class="titulosop">
            Ciudad
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su ciudad de residencia">
        <div class="titulosop">
            Barrio
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su barrio de residencia">
        <div class="titulosop">
            Calle
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su calle de residencia">
        <div class="titulosop">
            Número
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su número/altura de calle">

    </div>
</section>