<div *ngIf="modo==='normal'">
    <div class="seccion">
        <div class="nombre">
            <div class="ico">
    
            </div>
            <div class="label">
                Ofertas y promociones
            </div>
            
        </div>
    </div>
    <section class="margensection">
        <!-- <div class="contanerselectors">
            <selector-generico *ngFor="let item of gaseosas" [catego]="item" ></selector-generico>
        </div> -->
        <div class="containermainprod">
            <lista-productos-view [articulosIn]="promos"  [modelo]="'promociones'"></lista-productos-view>
            <lista-productos-view [articulosIn]="ofertas"  [modelo]="'oferta'"></lista-productos-view>
        </div>
    </section>
</div>
<mmax-helpfootbar></mmax-helpfootbar>

<foo-mariano-max></foo-mariano-max>