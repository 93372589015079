<div normal>
       <div class="bodyborrar">
        <div class="titulo">
                Vaciar Carrito de Compras
        </div>
        <div class="leyendavaciar">
                Esta acción elimina todo los elementos agregados al carrito de compras. ¿Desea eliminar todos los productos?
        </div>
        <div class="botones">
                <button class="buttonpc whibutton" (click)="cancelar()">Cancelar</button>
                <button class="buttonpc redbutton spacerRed" (click)="vaciar()" >Vaciar</button>
        </div>
       </div>
</div>


