import { NgModule } from '@angular/core';
import { ModalService } from './modal-utils/modal.service';
import { ModalComponent } from './modal-utils/modal/modal.component';
import { FollowBarDirective } from './effects-utils/follow-bar/follow-bar.directive';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [ModalComponent, FollowBarDirective],
  imports: [
    CommonModule,
  ],
  exports: [ModalComponent,FollowBarDirective],
  providers: [ModalService],
})
export class AppSharedModule { }
