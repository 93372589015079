import { Component, OnInit, Input } from '@angular/core';
import { HistorialPedidos } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'estados-traking-mmax',
  templateUrl: './estados-traking-mmax.component.html',
  styleUrls: ['./estados-traking-mmax.component.scss']
})
export class EstadosTrakingMmaxComponent implements OnInit {
  @Input() myEstado: string;

  constructor(private serv: MarianomGeneral) {
    this.iconreference = '../../assets/icons/checkpedidos.svg';
    this.estados = [];
    this.pagoDiferido = this.serv.getPagoDiferido();
  }

  ngOnInit(): void {
    this.pedido = this.serv.getJistorial();
    this.estado = this.pedido.situacion;
    this.myEstado = this.myEstado || '';

    if (this.pagoDiferido === 'N') {
      
      this.estados = [
        {
          nombre: 'En espera de Pago',
          activado: false,
        },
        {
          nombre: 'Pagado',
          activado: false,
        },
        {
          nombre: 'En preparación',
          activado: false,
        },
        {
          nombre: 'Listo para retirar',
          activado: false,
        },
        {
          nombre: 'Entregado',
          activado: false,
        },
      ]
    }
    if (this.pagoDiferido === 'E') {
      this.estados = [
        {
          nombre: 'En preparación',
          activado: false,
        },
        {
          nombre: 'Listo para retirar',
          activado: false,
        },
        {
          nombre: 'Pagado',
          activado: false,
        },
        {
          nombre: 'Entregado',
          activado: false,
        },
      ]
    }

    if (this.pagoDiferido === 'S') {
      this.estados = [
        {
          nombre: 'En preparación',
          activado: false,
        },
        {
          nombre: 'Listo para retirar',
          activado: false,
        },
        {
          nombre: 'Pagado',
          activado: false,
        },
        {
          nombre: 'Entregado',
          activado: false,
        },
      ]
    }
    let encontrado = false;
    // console.log(this.estado);
    for (let index = 0; index < this.estados.length; index++) {
      const element = this.estados[index];
      
      if (this.estados[index].nombre === this.estado) {
        encontrado = true;
      }
      if (encontrado) {
        // console.log("Estado activado");
        this.estados[index].activado = true;
        encontrado = false;
      }

    }

  }

  iconreference: string;
  estados: EstadoView[];
  pedido: HistorialPedidos;
  estado: string;
  pagoDiferido: string;
}

interface EstadoView {

  nombre: string,
  activado: boolean
}