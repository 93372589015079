<div *ngIf="mode==='normal'">
    <div class="contenerdor" [ngStyle]="{'background':color}">
        <div class="contenedorsub" [ngClass]="{'myline': categoria.desplegado}" (click)="onDeplegar(); irAcategorias(categoria)" onclick="window.scrollTo(0,0)">

            <div class=" comunnivel marker ">
                <img class="imageico" [src]="categoria.imagen">
            </div>

            <div class="comunnivel label">
                {{categoria.nombre}}
            </div>

            <button [ngClass]="{'clicked': categoria.desplegado}" type="button" class="comunnivel chevbrow btn">

            </button>
        </div>

        <div [@openClose]="categoria.desplegado ? 'open' : 'closed'" class="subcategorias"
            *ngFor="let sub of categoria.subCategorias">
            <div class="subcontenedor" (click)="onDeplegarsubtwo(sub);irAmarcas(sub);abrirCerrar()" onclick="window.scrollTo(0,0)"
                [ngClass]="{'plawer': sub.desplegado}">
                <div class="comunnivel plus" *ngIf='sub.desplegado; else restblock'>
                    -
                </div>

                <ng-template #restblock>
                    <div class="comunnivel plus">
                        +
                    </div>

                </ng-template>

                <div class="comunnivel subcategoriadecat">
                    {{sub.nombre}}
                </div>
                <div class="comunnivel pronner">

                </div>

            </div>

            <div [@openClose]="sub.desplegado ? 'open' : 'closed'" class="subcategorias"
                *ngFor="let tersub of sub.subCategorias; index as i">

                <div (click)="irAmarcas(tersub);activartirdcat(tersub,sub);abrirCerrar()" onclick="window.scrollTo(0,0)" *ngIf='(sub.subCategorias.length -1)!=i'
                    class="subcontenedor">
                    <div class="comunnivel plus">

                    </div>

                    <div [ngClass]="{'brabelavel': tersub.desplegado}" class="comunnivel subcategoriadecat">
                        {{tersub.nombre}}
                    </div>

                    <div class="comunnivel pronner">

                    </div>

                </div>

                <div (click)="irAmarcas(tersub);activartirdcat(tersub,sub)" onclick="window.scrollTo(0,0)" *ngIf='(sub.subCategorias.length -1)==i'
                    class="subcontenedor myline">
                    <div class="comunnivel plus">

                    </div>

                    <div [ngClass]="{'brabelavel': tersub.desplegado}" class="comunnivel subcategoriadecat">
                        {{tersub.nombre}}
                    </div>

                    <div class="comunnivel pronner">

                    </div>

                </div>
            </div>

        </div>

    </div>
</div>
