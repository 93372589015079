import { Component, OnInit } from '@angular/core';

import { ArticulosEcommerce } from 'projects/ecommerce-generico/src/public-api';

import { MarianomGeneral } from '../../app-shared/marianom-general.service';

import { CheckboxSubcategoria, CheckboxCategorias } from '../../app-shared/etc/categoria-checkbox.interface';


@Component({
  selector: 'app-frecuentes-mmaxview',
  templateUrl: './frecuentes-mmaxview.component.html',
  styleUrls: ['./frecuentes-mmaxview.component.scss']
})
export class FrecuentesMmaxviewComponent implements OnInit {

  constructor(private serv : MarianomGeneral ) { 
   this.mysucursalActiva = [];
  }

  ngOnInit(): void {
    let congas : CheckboxSubcategoria= {
      nombre : 'Con Gas',
      activada : false,
    }
    let singas : CheckboxSubcategoria= {
      nombre : 'Sin Gas',
      activada : false,
    }
    this.gaseosas= [
      {   nombre :  'Tipo de productos',
      categorias : [congas,singas]
      },
      {   nombre :  'Marca',
      categorias : [congas,singas]
      },
      {   nombre :  'Precio',
      categorias : [congas,singas]
      },

    ]
    
 
  }
 
  gaseosas : CheckboxCategorias[] ;
  mysucursalActiva : ArticulosEcommerce[]; // no es sucursal
}
