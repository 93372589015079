<div *ngIf="modo==='normal'">
    <div class="header">
        <div class="icon">
            <img [src]="icocatalogo" alt="">
        </div>
        <div class="title">
            Catálogo
        </div>
        <div class="descript">
            Precios vigentes desde el 20 de febrero al 20 de marzo.
        </div>
    </div>
    <div class="imagecontainer">
        <div class="space">

        </div>
        <div class="imagenes">
            <img [src]="slide1" alt="">
            <img [src]="slide2" alt="">
        </div>
        <div class="anotherspace">

        </div>

    </div>
    <div class="spacer">
        <div class="redframe">
            <div class="frame">
                <div class="textframe">
                    Sorteos y Promociones
                </div>
            </div>
        </div>
    </div>

    <div class="novedadescontainer">
        <div class="promocont">
            <div class="img">
                <img [src]="slide3" alt="">

            </div>
            <div class="texto">
                Promoción - Caja Navideña
            </div>
            <div class="desnovedad">
                Descripción breve del sorteo, sus condiciones y las fechas de validez.
            </div>
        </div>

        <div class="promocont">
            <div class="img">
                <img [src]="slide3" alt="">

            </div>
            <div class="texto">
                Promoción - Caja Navideña
            </div>
            <div class="desnovedad">
                Descripción breve del sorteo, sus condiciones y las fechas de validez.
            </div>
        </div>
        <div class="promocont">
            <div class="img">
                <img [src]="slide3" alt="">

            </div>
            <div class="texto">
                Promoción - Caja Navideña
            </div>
            <div class="desnovedad">
                Descripción breve del sorteo, sus condiciones y las fechas de validez.
            </div>
        </div>
    </div>




</div>