<div *ngIf="mode==='normal'">
  <div class="container">
    <div class="carousel">
      <ng-container *ngFor="let slide of slides; let i = index">
        <img *ngIf="i === currentSlide" [src]="slide.src" class="slide" @carouselAnimation />
      </ng-container>

      <div class="noselect mybtn">
        <div class="textocontainer">
          <span class="textogrande">
            Lunes a Jueves
          </span>
          <span class="textochico">
            20% de Descuento En verdulería
          </span>
        </div>
      </div>

    </div>
  </div>
  <div class="roundcontainer">
    <div class="roundedOne" *ngFor="let item of slidePosition ">
      <input [id]="item.id" type="checkbox" [checked]="item.activado" (change)="onchange(item)" />
      <label [for]="item.id"></label>
    </div>
  </div>

</div>
