import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-default-mmaxapp',
  templateUrl: './modal-default-mmax.component.html',
  styleUrls: ['./modal-default-mmax.component.scss']
})
export class ModalDefaultMmaxComponent implements OnInit {

  constructor() { 
    this.background="'../../assets/misc/Olas.svg'";
  }

  ngOnInit(): void {
  }
  background : string;

}
