
<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetavaciar">
                    <div class="titulo">
                        Reemplazo de Productos
                    </div>
                    <div class="leyendavaciar">
                        En el caso de que haya una faltante de uno de los productos que elegiste en tu compra te ofrecemos dos opciones.<br> 1) Mariano Max reemplazará tu producto por uno de características y precios similares (siempre el precio del producto elegido por Mariano Max, será menor al que vos seleccionaste). <br> 2) Tu producto no será reemplazado por ningún otro.
                       
                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton">Reemplazar con criterio Mariano Max</button>
                            <button class="buttonpc blubutton spacerRed">No Reemplazar</button>
                    </div>
            </div>
           


    </modal-default-mmaxapp>
</div>
