import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'reset-pass-mmax',
  templateUrl: './reset-pass-mmax.component.html',
  styleUrls: ['./reset-pass-mmax.component.scss']
})
export class ResetPassMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;
  @Input() form: string;

  constructor(private formBuilder: FormBuilder, private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg'
    this.modo = this.modo || 'normal';
    this.loader = this.loader || 'none';
    this.form = this.form || 'block';

  }
  salirMobile() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.router);
  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    new Image().src = this.imagen;
    this.resetform = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatpass: ['', [Validators.required, Validators.minLength(8)]],
      resetid: ['', [Validators.required, Validators.minLength(10)]]
    });
    // console.log(this.resetform);

  }
  get formFields() {
    return this.resetform.controls;
  }

  get userData(){
    return this.serv.getDatosUsuario();
  }

  async cambiarPass() {
    this.form = 'none';
    this.loader = 'flex';
    await this.serv.pedirCambioPass(this.serv.email).toPromise().catch(err => {

      this.modal.open('modal-alert-pass');
      console.log(err);
      this.errorForm = err.error.statussalida;
      console.error(err['statusText']);
      // this.createForm.reset();
    });
    this.loader = 'none';
    this.form = 'block';

  }


  async cambiarPassword(){
    
    await this.serv.cambiarContraseña(this.serv.email, this.formFields.password.value, this.formFields.resetid.value).toPromise().catch(err => {
      this.modal.open('modal-alert-pass');
      console.log(err);
      this.errorForm = err.error.statussalida;
      console.error(err['statusText']);
      
    });
    
    this.modal.close('reset-pass-modal-1');
    this.modal.open('login-modal-1');
  }


  imagen: string;
  resetform: FormGroup;
  createForm: FormGroup;
  errorForm: string;

}
