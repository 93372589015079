
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { CheckboxCategorias, CheckboxSubcategoria} from '../etc/categoria-checkbox.interface'
import { RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'selector-generico',
  templateUrl: './myselector-generico.component.html',
  styleUrls: ['./myselector-generico.component.scss'],
  animations : [GenericoSlideAnim  ]
})
export class MyselectorGenericoComponent implements OnInit,OnChanges {
  @Input() catego : CheckboxCategorias;
  @Input() remplazos: RemplazosMMax[];
  @Input() seleRem:number;
  @Input() mode : string;
  @Output()onSelect: EventEmitter<CheckboxSubcategoria>;
  constructor() {
    this.mode = this.mode || 'normal'
    this.desplegado = this.desplegado || false;
   
    this.catego = this.catego || { nombre : '', categorias : []};
    this.onSelect = new EventEmitter();
   }
  ngOnChanges(changes: SimpleChanges): void {
   // console.log(changes);
    let mival=changes?.seleRem?.currentValue;
    if (mival!=undefined) {
      this.selecionar(mival);
    }
    //;    
  }
  private selecionar(index:number){
    if (this.remplazos!=undefined) {
      this.remplazos.forEach(k=>{
        if (k.modo.toString()===index.toString()) {
         this.nombre=k.nombre;
         this.desplegado=false;
         this.seleRem=index;
        }
      })
    }
  
  }
  ngOnInit(): void {
    this.nombre=this.catego.nombre;
  }
  
  get selecionado() : string {
    return this.nombre;
  }
  onclik(subcate : CheckboxSubcategoria){
    this.nombre=subcate.nombre;
    this.desplegado=false;
    //console.log(subcate);
    this.onSelect.emit(subcate);
  }
  onRemplazSelect(event : RemplazosMMax){
    this.selecionar(Number.parseInt(event.modo));
  }
  desplegar(){
    this.desplegado=!this.desplegado;
  }
  desplegado : boolean
  nombre : string;
}
