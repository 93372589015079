import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ayuday-pregummax',
  templateUrl: './ayuday-pregummax.component.html',
  styleUrls: ['./ayuday-pregummax.component.scss'],
  animations : [
    trigger('openClose', [
      // ...
      state('open', style({
        'border-bottom':'solid 3px #0F2E8D',
       
      })),
      state('closed', style({
        'transform':' scaleX(1)',
      })),
      
      transition('open => closed', [
       animate('250ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('500ms ease-in-out')
      ]),
    ]),  
  ]
})
export class AyudayPregummaxComponent implements OnInit {
 @Input('mode')modo : string;
  constructor( private router :ActivatedRoute) {
    this.modo = this.modo || 'normal';
      this.menus=[
        {
           nombre : "Preguntas frecuentes",
           activa : false,
        },
        {
          nombre : "Contacto",
          activa : false,
       },

      ];
      this.menuselex=this.menus[0]

   }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
      
  });
    this.selecionarmenu( this.menuselex)
  }
  selecionarmenu(menu : MyMenu){
      this.menus.forEach(k=>{
        if (k.nombre===menu.nombre) {
          k.activa=true;
          this.menuselex=k;
        }
        else{
          k.activa=false;
        }
      })
  }
  menuselex : MyMenu;
  menus : MyMenu[];
}

interface MyMenu {
  nombre : string,
  activa : boolean,

}