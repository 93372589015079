
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import * as moment from 'moment';
import * as hash from 'hash.js';
import jwtDecode from "jwt-decode";
import { BehaviorSubject, forkJoin, Observable, of, Subscriber, Subscription, timer } from 'rxjs';
import { concatAll, first, map, subscribeOn, tap, switchMap } from 'rxjs/operators';
import { SucursalEcommerce } from './interfaces/sucursales-eccomerce.interface'
import { ArticulosEcommerce } from './interfaces/articulos-ecommerce.interface';
import { Usuario } from './interfaces/usuario.interface';
import { PromocionesEccomerce } from './class/promociones-class';
import { CategoriasWebMenu, MarcasEcommerce, BackUptArticulo } from '../public-api';
import { ArticulosGeneral } from './class/articulos-general.interface';
import { PorcentajePromo } from './class/porcentaje-promo.class';
import { MontoPromo } from './class/monto-calculado-promo';
import { DiferenciaPromo } from './class/diferencia-promo.class';
import { RemplazosMMax } from './interfaces/remplazos-ecommerce.interface';
import { PedidosMmaxEccomerce } from './interfaces/pedidos-eccomerce.interface';
import { ResultadosDeBusquedaInterface } from './interfaces/resultado-busqueda.interface';
import { HistorialPedidos } from './interfaces/historial-ecommerce-mmax';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OfertasEcommerce } from './interfaces/ofertas-ecommerce.interface';
import { TagsEcommerce } from './interfaces/tags-eccomerce.interface';
import { ModalService } from 'projects/app-shared/src/public-api';

@Injectable()

export class EcommerceGenericoService {
  imgn: any;
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer, private modal: ModalService) {
    this.remplazos = [];
    this.sucursales = [];
    this.articulosSucursalDefecto = [];
    this.promocionesEccomerce = [];
    this.sucursalactivatemp = new BehaviorSubject(undefined);
    this.environment = undefined;
    this.environmenturl = undefined;
    this.user = undefined;
    this.userProvider = new BehaviorSubject(undefined);
    this.sucursalestemp = new BehaviorSubject([]);
    this.lastSearch = new BehaviorSubject([]);
    this.minisearch = new BehaviorSubject([]);
    this.paginadorProvider = new BehaviorSubject(1);
    this.paginadorProviderOfertas = new BehaviorSubject(1);
    this.remplasosProvider = new BehaviorSubject([]);
    this.resultadodeBusqueda = new BehaviorSubject(undefined);
    this.contador = 0;
    this.nombreSitio = "";
    this.colores = [];
    this.tareas = [];
    this.ofertas = [];
    this.ofertasEnlazadas = [];
    this.ofertasTemp = new BehaviorSubject([]);
    this.idInterval = window.setInterval(() => { this.syncronizer() }, 12000);
    this.idIntervalForCart = window.setInterval(() => { this.cartSyncronizer() }, 1000);
    this.intervals = [this.idInterval, this.idIntervalForCart];
    this.categoriasWeb = [];
    this.marcasDestacadas = [];
    this.articulosdelServidor = [];
    this.carritoObservables = [];
    this.resultadosDebusqueda = [];
    this.historiaPedidos = [];
    this.tags = [];
    this.tagsActivos = new Map();
    this.historialtemp = new BehaviorSubject(this.historiaPedidos);
    this.fueBusquedaEscrita = true;
    this.oldSort = [];
  }



  initmaster = new Observable<void>(sus => {
    this.getJSON().subscribe(data => {
      this.environment = { endpoint: data['endpoint'], endpointversion: data['endpointversion'] };
      this.environmenturl = new URL(data['endpointversion'], data['endpoint']);
      //console.log(this.environmenturl);
      let urlLogo = data['logo_empresa'];
      this.iconLogo = data['icono_empresa'];


      //console.log(data);
      this.descargImgaen(urlLogo).subscribe(data => {
        this.logoSvg = data;
      });
      this.nombreSitio = data['nombre_empresa']
      this.sucursal_defecto = data['sucursal_defecto'];
      this.colores = data['colores'];
      var rootElement = document.documentElement;
      var primerColor = this.colores.primerColor;
      var segundoColor = this.colores.segundoColor;
      var tercerColor = this.colores.tercerColor;
      var textoColor = this.colores.textoColor;

      rootElement.style.setProperty("--primerColor", primerColor);
      rootElement.style.setProperty("--segundoColor", segundoColor);
      rootElement.style.setProperty("--tercerColor", tercerColor);
      rootElement.style.setProperty("--textoColor", textoColor);
      // console.log(this.sucursal_defecto);

      forkJoin([
        this.bajarSucursales(),
        this.configuracionEcommerce(),
        this.barjarMarcas(),
        this.barjarCategoriasdeArticulos(),
        this.barjarCategorias(),
        this.tagswebGet(),
        this.tagsarticulosweb(),
        //this.bajarArticulosPorSucursal(this.sucursal_defecto),
        this.ofertaswebec(),
        this.promocioneswebs(),
        this.inciarUsuario(),
      ]).subscribe(() => {
        this.tareasFinales().then(() => {
          sus.next();
          sus.complete();
        }).catch((err) => {
          sus.next();
          sus.complete();
        });

      }, err => this.tirarError(err, sus));



    });
  });





  async syncronizer() {
    this.contador++;
    // console.log('Se Inciaron Operaciones internas numero : ' + this.contador);
    forkJoin(this.tareas).pipe(concatAll()).subscribe(() => {

    }, (err) => {
      throw err;
    }, () => {
      // console.log('Se Finalizaron Operaciones internas');
    });
  }
  async cartSyncronizer() {
    if (this.carritoObservables.length > 0) {
      this.carritoObservables[0].subscribe(() => {

      }, (err) => {
        throw err;
      }, () => {
        this.carritoObservables = [];
      });
    }

  }
  getSucursalPorNumero(sucursal: number) {
    //console.log(sucursal);
    let nombre = ''
    this.sucursales.forEach(k => {
      if (sucursal == k.sucursal) {
        nombre = k.nombre;
      }
    })
    return nombre;
  }
  addtask(taks: Observable<void>) {
    this.tareas.push(taks);
  }
  ordenarporFechaHistorial(data: HistorialPedidos[]): HistorialPedidos[] {
    let arrayHistorial = data.sort(this.fechaHistorial);

    if (arrayHistorial.length > 30) {
      // console.log("Es mayor a 30");

      arrayHistorial = arrayHistorial.slice(0, 30);
    }
    // console.log(arrayHistorial);
    return arrayHistorial;
  }
  fechaHistorial = (a: HistorialPedidos, b: HistorialPedidos): number => {

    if (b.iddocumento > a.iddocumento) {
      return 1;
    }
    if (a.iddocumento > b.iddocumento) {
      return -1;
    }
    return 0;

    // if (moment(b.fecha, 'DD/MM/YYYY').isBefore(moment(a.fecha, 'DD/MM/YYYY'))) {
    //   return -1;
    // }
    // if (moment(a.fecha, 'DD/MM/YYYY').isBefore(moment(b.fecha, 'DD/MM/YYYY'))) {
    //   return 1;
    // }
    // return 0;
  }

  getMinPrecio(min: number) {
    this.min = min;
  }

  getMaxPrecio(max: number) {
    this.max = max;
  }


  menorAmayorPrecio = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getPrecioMayorista() > b.getPrecioMayorista()) {
      return -1;
    }
    if (a.getPrecioMayorista() < b.getPrecioMayorista()) {
      return 1;
    }
    return 0;
  }

  mayorAmenorPrecio = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getPrecioMayorista() > b.getPrecioMayorista()) {
      return 1;
    }
    if (a.getPrecioMayorista() < b.getPrecioMayorista()) {
      return -1;
    }
    return 0;
  }
  ordenAlfabetico = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getDescripCion() > b.getDescripCion()) {
      return -1;
    }
    if (a.getDescripCion() < b.getDescripCion()) {
      return 1;
    }
    return 0;
  }
  ordenAlfabeticoInvertido = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getDescripCion() > b.getDescripCion()) {
      return 1;
    }
    if (a.getDescripCion() < b.getDescripCion()) {
      return -1;
    }
    return 0;
  }

  ordenarArticulosPor(id: string) {
    if (id === '1') {//mayor precio es 1
      let sort = this.lastSearch.value.sort(this.menorAmayorPrecio);
      this.lastSearch.next(sort);
    }
    if (id === '0') {
      let sort = this.lastSearch.value.sort(this.mayorAmenorPrecio);
      this.lastSearch.next(sort);
    }
    if (id === 'a') {
      let sort = this.lastSearch.value.sort(this.ordenAlfabeticoInvertido);
      this.lastSearch.next(sort);

    }
    if (id === 'z') {
      let sort = this.lastSearch.value.sort(this.ordenAlfabetico);
      this.lastSearch.next(sort);
    }
  }

  filtrarRangoPrecio() {
    let sort = this.lastSearch.value.sort(this.mayorAmenorPrecio);
    // if (sort.length !== 0) {
    //   this.oldSort.push(sort);
    // }

    var newSort = [];
    sort.forEach(element => {
      
      if (element.getPrecioMayorista() < this.max && element.getPrecioMayorista() > this.min) {        
        console.log(element);
        newSort.push(element);
      }
    });

    this.lastSearch.next(newSort);
  }



  getNewToken(): Observable<any> {
    return this.httpClient.post(this.environment.endpoint + '/jwt/renew', {}, { headers: { "Authorization": this.user.token } });
  }

  limpiarysalir() {
    this.user = undefined;
    this.userProvider.next(undefined);
    this.resetEccomerce();
    localStorage.removeItem('currentUser');
  }

  getValidationToken(token) {
    var decoded = jwtDecode(token);
    var expirationDate = new Date(decoded['exp'] * 1000);
    var daste = moment().local(true).add(3, 'minutes').unix();
    var midate = new Date(daste * 1000);
    /*
    console.log('tiempo de expiracion');
    console.log(expirationDate);
    console.log('tiempo real');
    console.log(new Date());
    console.log('tiempo modificado');
    console.log(midate);
    console.log(expirationDate < midate);
    */
    return !(expirationDate <= midate);
  }

  cerrarPromos(): Observable<any> {
    // console.log(this.promocionesEccomerce);

    let promocionesaGuardar: BackUptArticulo[] = [];
    this.promocionesEccomerce.forEach(k => {
      if (k.counter > 0) {
        promocionesaGuardar.push(
          {
            marca: k.getMarca(),
            codigo: k.getCodigo(),
            cantidad: k.counter,
            precio: -k.getDescontado(),
            monto: -k.getDescontadoTotal(),
            reemplazo: 0,
          }
        );
      }
    })






    if (promocionesaGuardar.length > 0) {
      return this.postGenericoSeguro('itempedidoweb', { articulos: promocionesaGuardar });
    }
    else {
      return of(0);
    }

  }
  deletePromosCloud(): Observable<any> {
    let promocionesaGuardar: BackUptArticulo[] = [];
    this.promocionesEccomerce.forEach(k => {
      promocionesaGuardar.push(
        {
          marca: k.getMarca(),
          codigo: k.getCodigo(),
          cantidad: 0,
          precio: 0,
          monto: 0,
          reemplazo: 0,
        }
      );
    })

    if (promocionesaGuardar.length > 0) {
      return this.postGenericoSeguro('itempedidoweb', { articulos: promocionesaGuardar });
    }
    else {
      return of(0);
    }
  }

  resetEccomerce() {
    this.sucursalactiva.articulos.forEach(k => {
      this.eliminarPromos(k)
      //k.setCantidadInicial(0);
    });

    this.ajustarrpomo();
  }

  letresetJson() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'resetjson');
  }
  guardarpedido(pedido: PedidosMmaxEccomerce) {
    this.mypedido = pedido;
    this.mypedido.medios.forEach(k => {
      k.id = this.generarid(5);
      k.selecionado = false;

    })
  }

  verUltimoPedido() {
    return this.mypedido;
  }

  devolverMediosPagos() {
    // console.log(this.mypedido);

    return this.mypedido.medios;
  }

  obtenerPlanosdemedios(mediopago: number) {
    let params = new HttpParams();
    params = params.append('documento', this.mypedido.documento.toString());
    params = params.append('mediopago', mediopago.toString());
    return this.getGenericoSeguro(params, 'planesmediosweb');
  }
  cerrarFinaleccomerce(mediopago: number, plan: number) {

    return this.postGenericoSeguro('ordenpagoweb', {
      documento: this.mypedido.documento,
      mediopago: mediopago,
      plan: plan,
    });
  }

  getUserSync() {
    return this.user;
  }
  tokencheck = new Observable<void>(response => {
    if (this.user != undefined) {
      // console.log('Revisando Credenciales');
      if (this.getValidationToken(this.user.token) == false) {
        this.getNewToken().subscribe(data => {
          // console.log(data);
          localStorage.removeItem('currentUser')
          this.user.token = data.token;
          this.userProvider.next(this.user);
          localStorage.setItem('currentUser', JSON.stringify({ username: this.user.username, token: data.token }));
          console.log('Credenciales nuevas establecidas');
        })
      }
      else {
        // console.log('Credenciales en forma');
      }

    }

    response.complete();

  });


  getSucursalesProviders() {
    return this.sucursalestemp.asObservable();
  }
  getJSON(): Observable<any> {
    return this.httpClient.get('../../cfg/app-configs.json');
  }
  loginStart(username: any, password: any) {
    let nonce = Date.now();
    let firstHash = hash.sha256().update(username + ' ' + password).digest('hex');
    let secondHash = hash.sha256().update(nonce + ' ' + firstHash).digest('hex');


    return this.loginPost('/jwt/login', { usuario: username, hash: secondHash, nonce: nonce.toString() }).pipe(
      tap((data) => {
        // console.log('login Ok');
        localStorage.setItem('currentUser', JSON.stringify({ username: username, token: data['token'] }));
        this.user = { username: username, token: data['token'], sucursal: -1 };
        this.inciarUsuario().toPromise();
        this.tareasFinales()

      })
    );
  }
  async inicarMercadoPago() {
    let url = new URL("http://192.168.0.118:9003/dlr/checkoutpro")
    let data = await this.httpClient.post(url.toString(), {
      description: 'COMPRA MMAX',
      price: this.getTotal()
    }).toPromise().catch(e => {
      console.error(e);

    });
    return data;


    /*
    this.mercado = new MercadoPago(

    )
    */
  }

  async cambiarSucursalV2(idSucursal: string) {

    await this.postGenericoSeguro('cambiarsucursal', {
      sucursal: idSucursal.toString(),
    }).toPromise();

    let encontroSucursal = false;
    //busqueda manual offlline de la sucursal
    for (let k of this.sucursales) {
      if (k.sucursal.toString() === idSucursal.toString()) {
        this.sucursalactiva = k;
        //this.sucursalactiva.articulos=[];
        this.user.sucursal = k.numero;
        this.user.sucursalNombre = ' ' + k.nombre;
        encontroSucursal = true;
        break;
      }
    }
    if (encontroSucursal) {
      this.sucursal_defecto = idSucursal;

      this.restoEcomerceInit(idSucursal);
    }
    else {
      console.error('No se encontro la sucursal, y no tiene datos la misma');

    }

  }
  private async restoEcomerceInit(idSucursal) {
    //await this.bajarArticulosPorSucursal(idSucursal).toPromise();
    this.reset = true;
    await this.tareasFinales()
  }

  activarSucursal(id: string) {
    return new Observable<void>(subs => {

      for (let k of this.sucursales) {
        if (k.sucursal.toString() === id) {
          this.sucursalactiva = k;
          break;
        }
      }
      this.bajarArticulosPorSucursal(id).subscribe(data => {
        this.iniciarsucursal(data);
        subs.next();
        console.log('la tarea se termino de ejecutar');
        this.guardarSucursalUsuario(id).subscribe(data => {
          console.log(data);

          subs.complete();
        })


      })
    })

  }

  guardarSucursalUsuario(sucursalID: string) {
    return this.putGenericoSeguro('clienteweb', {
      nombre: this.user.nombre || '',
      direccion: this.user.direccion || '',
      telefonos: this.user.telefonos || '',
      dni: this.user.dni || 0,
      fechaNacimiento: this.user.fechaNacimiento || '',
      sucursal: sucursalID,
    });
  }

  crearUsuario(username: any, dni: any, email: any, password: any, sucursal: any): Observable<any> {
    /*
    "nombre":"",
  "direccion":"",
  "telefonos":"",
  "email":"",
  "ciudad":"",
  "dni":0,
  "fechaNacimiento":"1899-31-12",
  "sucursal":0,
  "password":""*/
    return this.postGenerico('clienteweb', {
      nombre: username,
      direccion: "",
      telefonos: "",
      email: email,
      ciudad: "",
      dni: dni,
      fechaNacimiento: "1899-31-12",
      sucursal: sucursal,
      password: password,
    });

  }

  pedirCambioPass(email: any): Observable<any> {
    return this.loginPost('/jwt/restore', { email: email }).pipe(
      tap((data) => {
        console.log("Mando MAIL: ", email);
      })
    );
  }

  cambiarContraseña(username: any, password: any, resetId: any) {

    let nonce = Date.now();
    let firstHash = hash.sha256().update(username + ' ' + password).digest('hex');

    // console.log("Body", { usuario: username, hash: secondHash, nonce: nonce, resetId: resetId.toString() });


    return this.loginPost('/jwt/restore', { usuario: username, hash: firstHash, nonce: nonce, resetid: resetId.toString() }).pipe(
      tap((data) => {

        console.log("Data:", data);

      })
    );
  }

  // cambiarContraseña(email: any, password: any): Observable<any> {
  //   return this.putGenerico('clienteweb', {
  //     email: email,
  //     password: password
  //   })
  // }  
  /*
  go Region
  */
  ira(arg0: string) {
    console.log(arg0);

  }

  getLogo() {
    //console.log(this.logoSvg);

    return this.logoSvg;
  }
  getNombreSitio() {
    return this.nombreSitio;
  }


  /*
  get Region
  */


  tirarError = ((err, sus: Subscriber<void>) => {
    sus.error(err);
    //console.log(err);
    this.modal.open('modal-error');

  });
  /*
  "dia":1,
    "horainicio":1000,
    "fecha":"1899-12-31"
  */

  cerrarPedido(dia: number, horainicio: number, fecha: string, modo: number) {
    return this.postGenericoSeguro('cerrarpedidoweb', {
      dia: dia,
      horainicio: horainicio,
      fecha: fecha,
      modo: modo,
      observacion: this.lastObs,
    });
  }

  cancelarPedidoWeb() {

    return this.postGenericoSeguro('cancelarpedidoweb', {}).subscribe((data) => {
      console.log(data);

    });
  }

  setObservacion(observacion: string) {
    this.lastObs = observacion || '';
  }
  verPedido(detalle: HistorialPedidos) {
    // console.log(detalle);
    this.detalleElegido = detalle;
  }
  getDetalleElegido() {
    return this.detalleElegido;
  }
  private iniciarsucursal(data) {
    this.sucursalactiva.articulos = data;
    this.sucursalactivatemp.next(this.sucursalactiva);
    //console.log(this.sucursalactiva);
    //console.log(data);
  }

  inciarUsuario() {

    return new Observable<void>(obs => {
      // modificar
      this.user = JSON.parse(localStorage.getItem('currentUser'));

      if (this.user == null) {
        // this.userProvider = new BehaviorSubject(undefined);
        this.userProvider.next(undefined);
        obs.next();
        obs.complete();
        return;
      }
      // console.log(this.user);
      if (!this.getValidationToken(this.user.token)) {
        this.userProvider.next(undefined);
        localStorage.removeItem('currentUser');
        obs.next();
        obs.complete();
        return;
      }
      //usuario valido
      //  console.log(this.user);

      this.obtenerdatosdeUsuario().subscribe(data => {
        this.actualizarUsuario(data);
        this.tareas.push(this.tokencheck);

        obs.next();
        obs.complete();

      }, err => {
        this.userProvider.next(undefined);
        localStorage.removeItem('currentUser');
        obs.next();
        obs.complete();
      });

    });

  }
  /*
  OFERTAS?
  */
  ofertaswebec() {
    let httparams = new HttpParams();
    return this.getGenerico(httparams, 'ofertaswebec').pipe(tap(data => {
      this.ofertasEnlazadas = data['registros'] as OfertasEcommerce[];
    }));
  }

  getOfertAStemp() {
    return this.ofertasTemp.asObservable();
  }
  /*
 OFERTAS?
 */
  /*area de tags*/
  tagswebGet() {
    let httparams = new HttpParams();

    return this.getGenerico(httparams, 'tagsweb').pipe(tap(data => {
      let mydata = data['registros'];
      if (mydata != undefined && Array.isArray(mydata)) {
        mydata.forEach(k => {
          this.tags.push({
            nombre: k['nombre'],
            articulos: [],
            tag: k['tag']
          });
        })
      }
    }))

  };

  tagsarticulosweb() {
    let httparams = new HttpParams();
    return this.getGenerico(httparams, 'tagsarticulosweb').pipe(tap(data => {
      this.tagsRelacion = data['registros'];
    }));
  }
  /*area de tags*/



  turnosdisponibles() {

    let params = new HttpParams();//"sucursal":""
    //params = params.append('sucursal',this.sucursalactiva.sucursal.toString())
    return this.getGenericoSeguro(params, 'turnosdisponibles').pipe(map(data => {
      return data['registros'];
    }));
  }
  /*  tareas compartidas  */
  async tareasFinales() {

    await this.bajarArticulosPorSucursal(this.sucursal_defecto).toPromise();


    /* tags - relacion - tags web*/
    if (this.tagsRelacion != undefined && Array.isArray(this.tagsRelacion)) {
      this.tags.forEach(k => {
        for (let tag of this.tagsRelacion) {
          if (k.tag == tag.tag) {
            k.articulos.push(tag);
          }
          else {
            break;
          }
        }
      });

    }
    let registros: ArticulosEcommerce[] = [];
    let registro: ArticulosEcommerce;

    this.articulosSucursalDefecto.forEach(k => {
      let remplasoss: RemplazosMMax[] = []


      this.remplazos.forEach(k => {
        let remplaceme = Object.assign({}, k)
        remplaceme.idcss = this.generarid(4);
        remplaceme.activo = false;
        remplasoss.push(remplaceme);
      });
      let imgenurl = new URL(k['foto'], this.environmenturl).toString();
      registro = new ArticulosEcommerce
        (
          k['marca'],
          k['codigo'],
          k['precio'],
          k['precionormal'],
          k['descripcion'],
          k['unidadesxb'] | 0,
          k['presentacion'],
          k['pesoxb'] | 0,
          k['pesable'],
          k['pesableporunidad'],
          k['nombremarca'],
          k['cantidad'],
          k['coeficiente'],
          k['preciopor'],
          k['unidaddmedida'],
          k['cantidadmayorista'],
          k['preciomayorista'],
          k['etiquetamedida'],
          imgenurl,
          -1,
          remplasoss || [],
        )

      //registro.cantiadadpedido=0;
      //registro.setFoto(new URL(k['foto'],this.environmenturl).toString());
      //registro.enlapromo = [];
      //this.armarFoto(registro);

      this.tags.forEach(k => {
        for (let articulo of k.articulos) {
          if (articulo.codigo == registro.getCodigo() && articulo.marca == registro.getMarca()) {
            registro.setTag({ tag: k.tag });

          }
        }
      });

      if (registro.getDescripCion()[0] === "*") {
        let newDesc = registro.getDescripCion().slice(1);
        registro.descripcion = newDesc;
      }



      registros.push(registro)
    });

    this.iniciarsucursal(registros);

    /*OFERTAS*/
    let fecha = new Date();
    for (let ofertas of this.ofertasEnlazadas) {
      this.sucursalactiva.articulos.forEach(k => {
        if (k.getCodigo() == ofertas.codigo && k.getMarca() == ofertas.marca) {

          k.setdeOferta(ofertas);
          this.ofertas.push(k);

        }
      });
    }
    this.ofertas = this.ofertas.filter((item, index) => {
      return this.ofertas.indexOf(item) === index;
    });

    let newOfertas = [];
    for (let i = 0; i < this.ofertas.length; i++) {
      const element = this.ofertas[i];

      if (element.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {

        newOfertas.push(element);

      }
    }
    this.ofertas = newOfertas;

    if (this.reset === true) {
      for (let i = 0; i < this.ofertas.length; i++) {
        const ofertaInicial = this.ofertas[i];
        // console.log("\n\n\nOFERTA this.ofertas INDEX ", i + ": ", ofertaInicial);

        let newOfertas = this.ofertas;
        // newOfertas.splice(i, 1);
        console.log(newOfertas);

        for (let index = 0; index < newOfertas.length; index++) {
          const ofertaDuplicada = newOfertas[index];
          // console.log("OFERTA newOfertas INDEX ", index + ": ", ofertaDuplicada);

          // console.log("COMPARACION DE ", ofertaInicial, " Y ", ofertaDuplicada);

          if ((ofertaInicial.marca & ofertaInicial.codigo) === (ofertaDuplicada.marca & ofertaDuplicada.codigo)) {
            // console.log("LA OFERTA SI SE REPITE");

            newOfertas.splice(index, 1);
            // i = 0;
          } else {
            // console.log("LA OFERTA NO SE REPITE");

          }
        }

      }
    }


    this.paginadoOfertas();
    this.calcularPromociones();
    if (this.user != null) {
      await this.iniciarCarrito().toPromise();
      this.getHistorialDePedidos().toPromise();
    }


  }

  /*  END tareas compartidas  */
  actualizarUsuario(response) {
    let data = response['registros'][0];
    this.user.ciudad = data['ciudad'];
    this.user.clienteol = data['clienteol'];
    this.user.direccion = data['direccion'];
    this.user.dni = data['dni'];
    this.user.email = data['email'];
    this.user.empresa = data['empresa'];
    this.user.fechaNacimiento = data['fechanacimiento'];
    this.user.nombre = data['nombre'];
    this.user.sucursal = data['sucursal'];
    this.user.telefonos = data['telefonos'];
    if (this.user.sucursal != undefined && this.user.sucursal != 0) {
      this.sucursales.forEach(k => {
        if (this.user.sucursal == k.sucursal) {
          this.sucursalactiva = k;
          this.user.sucursalNombre = ' ' + k.nombre;
          this.sucursal_defecto = this.user.sucursal.toString();
        }
      })
    }

    this.userProvider.next(this.user);
  }

  obtenerdatosdeUsuario() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'clienteweb');

  }
  obtenerdatosdeUsuarioV2() {
    let params = new HttpParams();
    return this.getGenerico(params, 'clienteweb');

  }
  barjarMarcas() {
    let params = new HttpParams();
    return this.getGenerico(params, 'marcasweb').pipe(tap((data) => {
      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        this.marcasDestacadas.push({
          articulos: element['articulos'],
          destacada: element['destacada'],
          imagen: imgenurl,
          marca: element['marca'],
          nombre: element['nombre'],
          urlexterna: element['urlexterna'],
        })
      });
    }));


  }

  getMarcasDestacadas() {
    let marcas: MarcasEcommerce[] = []
    this.marcasDestacadas.forEach(k => {
      marcas.push(k);
    })
    return marcas;
  }

  getOfertas() {
    return this.ofertas;
  }

  getNumPagOfertas() {
    return this.ofertas.length / 15;
  }

  paginadoOfertas() {

    let paginado: ArticulosGeneral[] = [];

    // console.log("LENGTH OFERTAS: ", this.ofertas.length);

    let paginas = this.ofertas.length / 15;
    this.paginadorProviderOfertas.next(paginas);
    //console.log(paginas)
    if (paginas > 1) {
      for (let index = 0; index < 15; index++) {
        // console.log(this.ofertas[index]);

        paginado.push(this.ofertas[index]);

      }
      // this.ofertas = paginado;
      this.paginaActualOfertas = 0;

    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.ofertas;
      // this.ofertas = paginado;
      this.paginaActualOfertas = 0;
    }
    // console.log("PAGINADO: ", paginado);

    // this.lastSearch.next(paginado);
  }
  /**
  * 
  * categorias 
  */
  /**
   *  bajar categorias parte 2
   */
  barjarCategorias() {
    let params = new HttpParams();
    return this.getGenerico(params, 'categoriasweb').pipe(tap(data => {
      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        this.categoriasWeb.push({
          articulos: element['articulos'],
          categoria: element['categoria'],
          imagen: imgenurl,
          nombre: element['nombre'],
          orden: element['orden'],
          padre: element['padre'],
          subCategorias: [],
          desplegado: false,
        });
      });
      this.ordenarCategorias();
    }));
  }

  getExtraResultadosProvider() {
    return this.resultadodeBusqueda.asObservable();
  }



  /**
   *  bajar categorias parte 1
   */
  barjarCategoriasdeArticulos() {
    let params = new HttpParams();
    return this.getGenerico(params, 'categoriasarticulosweb').pipe(tap(data => {
      /*
    categoria: 101
    codigo: 888
    marca: 60
    marca: 3065, codigo: 7, categoria: 8
    */
      //categoria...
      this.mapaPedidos = new Map();

      let midata: { marca: number, codigo: number, categoria: number }[] = data['registros'] as any[];
      midata.forEach(k => {

        if (this.mapaPedidos.has(k.categoria)) {
          let arra = this.mapaPedidos.get(k.categoria);
          arra.push({
            marca: k.marca,
            codigo: k.codigo
          });
          this.mapaPedidos.set(k.categoria, arra);
        }
        else {
          this.mapaPedidos.set(k.categoria, [{ marca: k.marca, codigo: k.codigo }]);
        }
      })
      //console.log(this.categoriasWeb);
      //console.log(this.mapaPedidos);
    }))


  }


  private async ordenarCategorias() {
    let categorias: CategoriasWebMenu[] = [];

    this.categoriasWeb.forEach(categoria => {
      if (categoria.padre == 0) {
        this.categoriasWeb.forEach(subcategoria => {
          if (categoria.categoria == subcategoria.padre) {
            this.categoriasWeb.forEach(tiercategoriatres => {
              if (subcategoria.categoria == tiercategoriatres.padre) {
                subcategoria.subCategorias.push(tiercategoriatres);
              }
            })
            categoria.subCategorias.push(subcategoria);
          }
        })
        categorias.push(categoria);
      }

    });
    this.categoriasWeb = categorias;
    // console.log(this.categoriasWeb);


  }

  /**
   * 
   * @returns a array of categorias
   */
  getCategoriasWeb() {
    let categorias: CategoriasWebMenu[] = [];
    this.categoriasWeb.forEach(k => {
      categorias.push(k);
    })
    return categorias;
  }
  /**
 * 
 * end categorias 
 */
  bajarSucursales() {
    let params = new HttpParams();
    var fundentroOtraFun = () => {


      let sucursal = this.sucursales[0];
      if (sucursal) {
        console.log('SE ESTABLECIO UNA SUCURSAL DEFECTO' + ' ' + sucursal.nombre);
        this.sucursal_defecto = sucursal.sucursal.toString();
        this.sucursalactiva = sucursal;
        this.sucursalactiva.articulos = [];
        this.articulosSucursalDefecto = [];
      }
    }
    return this.getGenerico(params, 'sucursalesweb').pipe(tap(data => {
      let suck = data['registros'] as any[];
      // console.log(suck);

      if (suck == undefined || suck.length < 0) {
        // console.log('lal');

        alert('Sin Sucursales');//cambiar pro propio
        //throw // ERROR CRITICO REMPLAZAR por error propio
      }

      let sucu: SucursalEcommerce;
      suck.forEach(sucursal => {
        sucu = sucursal as SucursalEcommerce;
        sucu.articulos = [];
        this.sucursales.push(sucu);
        if (sucu.sucursal.toString() === this.sucursal_defecto.toString()) {
          this.sucursalactiva = sucu;
          this.sucursalactiva.articulos = [];
        }

      })
      if (this.sucursalactiva) {
        if (this.sucursalactiva.articulos) {
          //tenemos sucursal, todo bien 
        }
        else {
          fundentroOtraFun()
        }
      }
      else {
        fundentroOtraFun()
      }





      this.sucursalestemp.next(this.sucursales);
    }))
  }

  configuracionEcommerce() {
    //configuracionecommerce
    let params = new HttpParams();
    return this.getGenerico(params, 'configuracionecommerce').pipe(tap(data => {
      //console.log(data);
      /*
      //sucurusale por fecte
      this.sucursales.forEach(k=>{
      //  console.log(data['sucursal']);
        
        if (k.sucursal.toString()=== data['sucursal']) {
            this.sucursalactiva=k;
            this.sucursalactiva.articulos=[];
            this.promocionesEccomerce=[];
        }
      });*/
      //console.log(data['reemplazos'] );
      console.log("Configuraciones Data: ", data);
      // console.log("Suc: ", this.sucursales);

      this.sucursal_defecto = data['sucursal'];

      let rempla = data['reemplazos'] as RemplazosMMax[];
      if (rempla != undefined) {
        this.remplazos = rempla;
        this.remplasosProvider.next(this.remplazos);
      }

      this.montoMinimo = data['montoMinimo'];
      this.pagoDiferido = data['pagoDiferido'];
      this.envioHabilitado = data['envioHabilitado'];
      this.registroHabilitado = data['registroHabilitado'];
    }));

  }

  getURLMap() {
    let sucact = this.getSucursalActiva();
    // console.log(sucact);
    if (sucact) {
      this.urlmap = sucact.urlmap;
      return this.urlmap;
    }
    else {
      return '';
    }


  }

  getRemplasosProvider() {
    return this.remplasosProvider.asObservable();
  }

  getMontoMinimo() {
    return this.montoMinimo;
  }

  getPagoDiferido() {
    return this.pagoDiferido;
  }

  getEnvioHabilitado() {
    return this.envioHabilitado;
  }

  getRegistroHabilitado() {
    return this.registroHabilitado;
  }

  getSucursalDefecto() {
    return this.sucursal_defecto;
  }

  // getData(url: string): Observable<string> {
  //   //console.log(url);
  //   return this.httpClient
  //     .get(url, { responseType: 'blob' })
  //     .pipe(switchMap((response) => this.readFile(response)));
  // }

  // private readFile(blob: Blob): Observable<string> {
  //   return Observable.create((obs) => {
  //     const reader = new FileReader();

  //     reader.onerror = (err) => obs.error(err);
  //     reader.onabort = (err) => obs.error(err);
  //     reader.onload = () => obs.next(reader.result);
  //     reader.onloadend = () => obs.complete();

  //     return reader.readAsDataURL(blob);
  //   });
  // }


  // compressImage(src, newX, newY) {
  //   return new Promise((res, rej) => {
  //     const img = new Image();
  //     img.src = src;
  //     img.onload = () => {
  //       const elem = document.createElement('canvas');
  //       elem.width = newX;
  //       elem.height = newY;
  //       const ctx = elem.getContext('2d');
  //       ctx.drawImage(img, 0, 0, newX, newY);
  //       const data = ctx.canvas.toDataURL();
  //       res(data);
  //     }
  //     img.onerror = error => rej(error);
  //   })
  // }

  bajarImgaen(articulo: ArticulosGeneral) {
    let params = new HttpParams();

    return this.httpClient.get(articulo.getFotoImagen(), {
      params, observe: 'response', responseType: 'blob'
    }).pipe(tap(data => {

      // let img = data.url;
      // this.getData(img).subscribe(
      //   (imgData) => {
      //     this.compressImage(imgData, 265, 100).then((imagen) => {
      //       this.imgn = imagen;
      //     });

      //   },
      //   (err) => console.log(err)
      // );


      // let img = data.url;
      // let canvas = document.createElement('canvas');
      // canvas.width = img.clientWidth;
      // canvas.height = img.clientHeight;
      // let context = canvas.getContext('2d');
      // context.drawImage(img, 265, 100);
      // canvas.toBlob(function (blob) {
      //   let newBlob = blob;
      //   console.log(newBlob);

      // }, 'image/png');

      articulo.setMyBlob(data.body);
      articulo.setFoto(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(articulo.getMyBlob())));
      // console.log(articulo.foto);

    }))

  }
  descargImgaen(imagensrc: string,) {
    let params = new HttpParams();
    return this.httpClient.get(imagensrc, {
      params, observe: 'response', responseType: 'blob'
    }).pipe(map((data) => {

      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.body))
    }));

  }
  /**
   * bajar articulos segun sucursal
   */
  bajarArticulosPorSucursal(sucursal: string) {

    let params = new HttpParams();
    params = params.append('sucursal', sucursal);

    return this.getGenerico(params, 'articulosweb').pipe(tap(data => {
      this.articulosSucursalDefecto = data['registros'];
    }));
  }
  /*
  async bajarTdaslasFotos(myfotos: Observable<any>[]) {
    forkJoin(myfotos).pipe(concatAll()).subscribe(()=>{
      //console.log(data);
      
    });
  }*/

  /**
   * 
   * carrito //revisar precios
   */
  getCarrito() {
    let carrito: ArticulosGeneral[] = [];
    this.sucursalactiva?.articulos.forEach(k => {
      if (k.getCantidad() > 0) {
        carrito.push(k);
      }
    })

    return carrito;
  }

  getTotal() {
    let tota = 0;
    this.sucursalactiva?.articulos.forEach(k => {
      tota += k.getPrecioCalculado();
    })
    return tota;
  }

  getCantidadTotal() {
    let cantidad = 0;
    this.sucursalactiva?.articulos.forEach(k => {
      cantidad += k.getCantidad();
    })
    return cantidad;
  }
  /**
   * 
   *  promociones.get
   */
  promocioneswebs() {
    let params = new HttpParams();//"sucursal":""
    //params = params.append('sucursal',this.sucursalactiva.sucursal.toString())
    return this.getGenerico(params, 'promocioneswebec').pipe(tap(data => {

      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        if (element['porcentaje'] != 0) {
          this.promocionesEccomerce.push(new PorcentajePromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          ))
        }
        if (element['monto'] > 0) {
          this.promocionesEccomerce.push(new MontoPromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          ))
        }
        if (element['monto'] < 0) {
          this.promocionesEccomerce.push(new DiferenciaPromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          ))
        }

      })
    }
    ));
  }

  eliminarPromos(articulo: ArticulosGeneral) {
    articulo.setCantidad(0);
    articulo.setModificacionPrecio(0);
    articulo.getPromos().forEach((promo: PromocionesEccomerce) => {
      promo.resetArticulos();
    });
  }
  /**
   *  
   * @deprecated
   */
  async sumarpromo(promocion: ArticulosGeneral, numero: number) {
    promocion.forzarAddPromo(numero);
  }

  async ajustarrpomo() {
    //console.log(this.promocionesEccomerce);
    /*
    let obsear : Observable<void>[]= []
    
    this.promocionesEccomerce.forEach(promo=>{
      obsear.push(promo.validarPromo)
    });
    console.log(this.promocionesEccomerce);
    
    forkJoin(obsear).pipe(concatAll()).subscribe(data=>{
      
    })*/

    this.sincronizeBackup();


  }

  sincronizeBackup() {
    this.carritoObservables.unshift(this.ajustarCuentas())
  }



  iniciarCarrito() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'itempedidoweb').pipe(tap((data) => {
      this.articulosdelServidor = data['registros'] as BackUptArticulo[];
      this.setBackupToMain(this.articulosdelServidor);
    }))
  }
  async setBackupToMain(backup: BackUptArticulo[]) {
    backup.forEach(element => {
      this.sucursalactiva.articulos.forEach(articulo => {
        if (((element as BackUptArticulo).marca == articulo.getMarca()) && ((element as BackUptArticulo).codigo == articulo.getCodigo())) {
          (articulo as ArticulosGeneral).setCantidad((element as BackUptArticulo).cantidad);
          (articulo as ArticulosGeneral).setCantidadInicial((element as BackUptArticulo).cantidad);

          this.tienePromoArticulo(articulo).forEach(k => {
            articulo.setPromo(k);
          });

          (articulo as ArticulosGeneral).operacionesExtras();
        }
      });
      /*
      this.promocionesEccomerce.forEach(articulo => {
        if (((element as BackUptArticulo).marca==articulo.getMarca()) && ((element as BackUptArticulo).codigo==articulo.getCodigo())) {
          (articulo as ArticulosGeneral).setCantidad((element as BackUptArticulo).cantidad);
          (articulo as ArticulosGeneral).setCantidadInicial((element as BackUptArticulo).cantidad);
        }
      });*/


    });
  }
  ajustarCuentas() {
    // no tiene cantidad y si tiene cantidad en el backup
    //si viene un precio diferente entre el del backup y el que tengo de la sucursal hay que avisar que aumento
    /*if((articulo as ArticulosGeneral).getCantidad()==0 && (element as BackUptArticulo).cantidad>0)
      {
      }   
    */
    return new Observable<void>(sub => {
      if (this.user != undefined) {
        let articulosAlcambio = [];
        this.sucursalactiva.articulos.forEach(articulo => {

          if ((articulo as ArticulosGeneral).getCantidadInicial() != (articulo as ArticulosGeneral).getCantidad()) {

            if //hay una modificacion y hay mas de lo que tenia en el backup
              ((articulo as ArticulosGeneral).getCantidad() > (articulo as ArticulosGeneral).getCantidadInicial()) {
              articulosAlcambio.push({
                marca: (articulo as ArticulosGeneral).getMarca(),
                codigo: (articulo as ArticulosGeneral).getCodigo(),
                cantidad: (articulo as ArticulosGeneral).getCantidad(),
                precio: (articulo as ArticulosGeneral).getPrecio(),
                monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
                reemplazo: articulo.getRemplazo(),
              });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());

            }
            else  //cantidad inicial es mayor a la nueva
            {
              articulosAlcambio.push({
                marca: (articulo as ArticulosGeneral).getMarca(),
                codigo: (articulo as ArticulosGeneral).getCodigo(),
                cantidad: (articulo as ArticulosGeneral).getCantidad(),
                precio: (articulo as ArticulosGeneral).getPrecio(),
                monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
                reemplazo: articulo.getRemplazo(),
              });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
            }

          }

        });
        /*
        this.promocionesEccomerce.forEach(articulo=>{
          if ( (articulo as ArticulosGeneral).getCantidadInicial()!=(articulo as ArticulosGeneral).getCantidad()) {
            
            if //hay una modificacion y hay mas de lo que tenia en el backup
            ((articulo as ArticulosGeneral).getCantidad()>(articulo as ArticulosGeneral).getCantidadInicial()){
              articulosAlcambio.push({
                marca:(articulo as ArticulosGeneral).getMarca(),
                codigo:(articulo as ArticulosGeneral).getCodigo(),
                cantidad:(articulo as ArticulosGeneral).getCantidad(),
                precio:(articulo as ArticulosGeneral).getPrecio(),
                monto:(articulo as ArticulosGeneral).getCantidad()*(articulo as ArticulosGeneral).getPrecio(),
              });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
              
            }
            else  //cantidad inicial es mayor a la nueva
            {
              articulosAlcambio.push({
                marca:(articulo as ArticulosGeneral).getMarca(),
                codigo:(articulo as ArticulosGeneral).getCodigo(),
                cantidad:(articulo as ArticulosGeneral).getCantidad(),
                precio:(articulo as ArticulosGeneral).getPrecio(),
                monto:(articulo as ArticulosGeneral).getCantidad()*(articulo as ArticulosGeneral).getPrecio(),
              });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
            }
      
         }
        })
        */
        //console.log(articulosAlcambioc);
        if (articulosAlcambio.length == 0) {
          sub.next();
          sub.complete();
          return;
        }
        this.postGenericoSeguro('itempedidoweb', { articulos: articulosAlcambio }).subscribe(data => {
          // console.log(data);
          sub.next();
          sub.complete();
        })

      }
      sub.next();
      sub.complete();
    });

  }

  async calcularPromociones() {
    let marcapromo1 = 0;
    let codigopromo1 = 0;

    let marcapromo2 = 0;
    let codigopromo2 = 0;
    this.promocionesEccomerce.sort(this.ordenarPromos);
    this.promocionesEccomerce?.forEach(promo => {
      if (promo.getregla1().length > 0) {
        marcapromo1 = Number.parseInt(promo.getregla1().split('and')[0].trim().split('=')[1]);
        codigopromo1 = Number.parseInt(promo.getregla1().split('and')[1].trim().split('=')[1]);
      }
      if (promo.getregla2().length > 0) {
        marcapromo2 = Number.parseInt(promo.getregla2().split('and')[0].trim().split('=')[1]);
        codigopromo2 = Number.parseInt(promo.getregla2().split('and')[1].trim().split('=')[1]);
      }
      /*
      console.log({
        marcapromo1:marcapromo1,
        codigopromo1:codigopromo1,
        marcapromo2:marcapromo2,
        codigopromo2:codigopromo2
      });*/

      this.sucursalactiva?.articulos?.forEach(k => {

        if (k.getMarca() == marcapromo1 && k.getCodigo() == codigopromo1) {

          promo.setArticulo1(k as ArticulosEcommerce);
          k.setPromo(promo);
          // k.setdeOferta(undefined);
        }
        if (k.getMarca() == marcapromo2 && k.getCodigo() == codigopromo2) {
          promo.setArticulo2(k as ArticulosEcommerce);
          k.setPromo(promo);
          // k.setdeOferta(undefined);
        }
        let promos = k.getPromos() as PromocionesEccomerce[];
        promos.sort(this.ordenarPromos);
        //console.log(k);

      });
      /*
              marcapromo1 = 0;
              codigopromo1 =0;
            
              marcapromo2=0;
              codigopromo2=0;
            console.log(promo);
      */
      //console.log(promo);


    })

  }
  ordenarPromos = (a: PromocionesEccomerce, b: PromocionesEccomerce): number => {
    if (a.getOrden() > b.getOrden()) {
      return 1;
    }
    if (a.getOrden() < b.getOrden()) {
      return -1;
    }
    return 0;
  }
  getPromociones() {
    let promociones: ArticulosGeneral[] = []
    this.promocionesEccomerce.forEach(k => {
      promociones.push(k);
    })
    return promociones;
  }

  cambiarPagina(event: number) {
    let paginado: ArticulosGeneral[] = []
    this.paginaActual = event;
    for (let index = this.paginaActual * 60; index < ((this.paginaActual + 1) * 60); index++) {
      if (this.resultadosDebusqueda[index] != undefined) {
        paginado.push(this.resultadosDebusqueda[index]);
      }


    }

    this.lastSearch.next(paginado);

  }
  cambiarPaginaOferta(event: number) {
    let paginado: ArticulosGeneral[] = []
    this.paginaActualOfertas = event;
    for (let index = this.paginaActualOfertas * 15; index < ((this.paginaActualOfertas + 1) * 15); index++) {
      if (this.ofertas[index] != undefined) {
        paginado.push(this.ofertas[index]);
      }


    }

    this.lastSearch.next(paginado);

  }
  getPaginas() {
    return this.paginadorProvider.asObservable();
  }
  getPaginasOfertas() {
    return this.paginadorProviderOfertas.asObservable();
  }
  /**
   * 
   * @param tag to filter in actual search
   */
  filtrarTag(tag: TagsEcommerce) {
    console.log(tag);
    if (!this.tagsActivos.has(-100)) {
      this.tagsActivos.set(-100, this.resultadosDebusqueda)
    }


    let refiltrado: ArticulosGeneral[] = [];
    this.resultadosDebusqueda.forEach(k => {
      for (let tags of k.getTag()) {
        if (tags.tag == tag.tag) {
          refiltrado.push(k);
          break;
        }
        else {

        }
      }

    });
    this.tagsActivos.set(tag.tag, refiltrado);
    this.resultadosDebusqueda = refiltrado;
    this.paginado();
    console.log(this.resultadosDebusqueda);
    console.log(this.tagsActivos);


  }
  cerrartag(tag: TagsEcommerce) {
    let tasact: number[] = [];
    let refiltrado: ArticulosGeneral[] = [];

    this.tagsActivos.forEach((art, tagnum) => {
      if (tag.tag == tagnum) {
        if (tag.selecionado) {
          tasact.push(tag.tag);
        }
      }
    });

    this.tagsActivos.get(-100).forEach(art => {
      art.getTag().forEach(tagArt => {
        tasact.forEach(tagsActivos => {
          if (tagArt.tag == tagsActivos) {
            refiltrado.push(art)
          }
        })
      })
    })
    if (tasact.length == 0) {
      this.resultadosDebusqueda = this.tagsActivos.get(-100);
    }
    else {
      this.resultadosDebusqueda = refiltrado;
    }

    this.paginado();

  }

  /**
   * 
   * @param event string come ups and start search 
   */
  iniciarbuqueda(event: string) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: event,
      tagsEnbusqueda: new Map(),
    };


    let contador = 0;
    this.paginaActual = 0;
    this.fueBusquedaEscrita = true;
    this.resultadosDebusqueda = [];
    let minirsultados: ArticulosGeneral[] = [];
    if (event.length > 1) {
      this.sucursalactiva?.articulos?.forEach(k => {
        if (k.getDescripCion().toLocaleLowerCase().includes(event.toLocaleLowerCase())) {
          this.resultadosDebusqueda.push(k);
          k.getTag().forEach(tag => {
            this.tags.forEach(tagreal => {
              tagreal.selecionado = false;
              if (tag.tag === tagreal.tag) {
                if (!(myBusquedaExtras.tagsEnbusqueda.has(tagreal.tag))) {

                  myBusquedaExtras.tagsEnbusqueda.set(tagreal.tag, tagreal);
                }
              }

            })

          })

          if (contador < 7) {
            minirsultados.push(k);
          }
          contador++;
          myBusquedaExtras.totaldeArticulos++;
        }
      });
      this.minisearch.next(minirsultados);
      this.paginado();
      this.resultadodeBusqueda.next(myBusquedaExtras);
      // console.log(myBusquedaExtras.tagsEnbusqueda);

      // console.log(articu);

    } else {
      this.lastSearch.next([]);
      this.resultadodeBusqueda.next({
        totaldeArticulos: 0,
        textodebusqueda: '',
      });
    }
  }
  private paginado() {
    let paginado: ArticulosGeneral[] = [];
    //console.log('numero de paginas');
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    //console.log(paginas)
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {

        paginado.push(this.resultadosDebusqueda[index]);

      }
      this.paginaActual = 0;

    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }

    this.lastSearch.next(paginado);
  }

  getAnchoPagina(ancho: number) {
    this.anchoPagina = ancho;
    return ancho;
  }
  /*
  
  */

  /**
   * puede tener o marca solo o codigo solo
   * 
   * @param marca a buscar
   * @param codigo a buscar
   */

  async busquedaCategoria(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre,
      categoria: categoriaPadre,
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;
    // console.log(categoria.subCategorias);

    let array = this.mapaPedidos.get(categoria.categoria) || [];
    if (categoria.subCategorias != undefined) {
      categoria.subCategorias.forEach(k => {
        array = array.concat(this.mapaPedidos.get(k.categoria));
        k.subCategorias.forEach(l => {
          array = array.concat(this.mapaPedidos.get(l.categoria));
        })
      })

    }
    if (array.length > 0) {
      // console.log(array);
      // let contador = 0;
      // for (let i = 0; i < array.length; i++) {
      //   const element = array[i];
      //   if (element != undefined) {
      //     contador += 1;
      //   }

      // }
      // console.log("ARTICULOS TOTALES en Array: ", contador);


      array.forEach(marcacod => {
        // console.log(marcacod);
        if (marcacod) {
          this.sucursalactiva?.articulos.forEach(k => {

            if (k.getMarca() == marcacod.marca && k.getCodigo() == marcacod.codigo) {
              if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
                newArticulos.push(k);
              }
              if (k.isdeoferta === false) {
                newArticulos.push(k);
              }
              // if (k.isdeoferta === true) {
              //   newArticulos.push(k);
              // }
              this.resultadosDebusqueda = newArticulos;
              // console.log(this.resultadosDebusqueda);

              // this.resultadosDebusqueda.push(k);
              if (this.resultadosDebusqueda.length === 0) {
                myBusquedaExtras.totaldeArticulos;
              }
              else {
                myBusquedaExtras.totaldeArticulos++;
              }
              // console.log(myBusquedaExtras.totaldeArticulos);

            }
          })
        }

      });
    }
    this.numBusqueda = this.resultadosDebusqueda.length;

    let paginado: ArticulosGeneral[] = [];
    // console.log(this.resultadosDebusqueda);

    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    //console.log(paginas)
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
    this.resultadodeBusqueda.next(myBusquedaExtras);
  }

  async iniciarbuquedaPorCodigoMarca(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre,
      categoria: categoriaPadre,
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;

    let array = this.mapaPedidos.get(categoria.categoria) || [];

    if (categoria.subCategorias != undefined) {
      categoria.subCategorias.forEach(k => {
        array = array.concat(this.mapaPedidos.get(k.categoria));
      })

    }
    if (array.length > 0) {

      array.forEach(marcacod => {
        // console.log(marcacod);
        if (marcacod) {
          this.sucursalactiva?.articulos.forEach(k => {

            if (k.getMarca() == marcacod.marca && k.getCodigo() == marcacod.codigo) {
              if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
                newArticulos.push(k);
              }
              if (k.isdeoferta === false) {
                newArticulos.push(k);
              }
              // if (k.isdeoferta === true) {
              //   newArticulos.push(k);
              // }
              this.resultadosDebusqueda = newArticulos;
              // console.log(this.resultadosDebusqueda);

              // this.resultadosDebusqueda.push(k);
              if (this.resultadosDebusqueda.length === 0) {
                myBusquedaExtras.totaldeArticulos;
              }
              else {
                myBusquedaExtras.totaldeArticulos++;
              }
              // console.log(myBusquedaExtras.totaldeArticulos);

            }
          })
        }

      });
    }
    this.numBusqueda = this.resultadosDebusqueda.length;
    // console.log(this.numBusqueda);

    // console.log(this.resultadosDebusqueda);

    let paginado: ArticulosGeneral[] = [];
    //console.log('numero de paginas');
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    //console.log(paginas)
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
    this.resultadodeBusqueda.next(myBusquedaExtras);
  }

  getNumBusqueda() {
    return this.numBusqueda;
  }

  generarid(dimencion: number): string {
    let result = '';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < dimencion; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /*
   Providers
  */
  getUsuarioProvider() {
    return this.userProvider.asObservable();
  }
  getSucursalesProvider() {
    return this.sucursalestemp.asObservable();
  }
  getActualSucursal() {
    return this.sucursalactivatemp.asObservable();
  }
  getLastSearchProvider() {
    return this.lastSearch.asObservable();
  }
  getMiniSearchProvider() {
    return this.minisearch.asObservable();
  }
  getSucursalActiva() {
    return this.sucursalactiva;
  }
  getFueBusquedaEscrita() {
    return this.fueBusquedaEscrita;
  }

  //https
  getGenericoSeguro(params: HttpParams, ruta: string) {
    return this.httpClient.get(this.environmenturl.toString() + ruta, {
      headers: { "Authorization": this.user.token }, params
    });
  }

  postGenericoSeguro(url: string, body: object) {
    // console.log("USER: ", this.user);

    return this.httpClient.post(this.environmenturl + url, body, {
      headers: { "Authorization": this.user.token }
    });

  }
  putGenericoSeguro(url: string, body: object) {
    return this.httpClient.put(this.environmenturl + url, body, {
      headers: { "Authorization": this.user.token }
    });

  }
  /*
   Historial Region
  */
  recompraDeHistorial(jistoriaRecompra: HistorialPedidos) {
    this.resetEccomerce();
    jistoriaRecompra.items.forEach(artback => {
      this.sucursalactiva.articulos.forEach(artreal => {
        if (artback.codigo == artreal.getCodigo() && artback.marca == artreal.getMarca()) {
          artreal.setCantidad(artback.cantidad);
          this.tienePromoArticulo(artreal).forEach(k => {
            artreal.setPromo(k);
          })
          artreal.operacionesExtras();
        }

      });
    });
    this.ajustarrpomo();

  }

  getHistorialDePedidos() {
    let httparams = new HttpParams();
    return this.getGenericoSeguro(httparams, 'pedidoweb').pipe(tap(data => {

      this.historiaPedidos = data['pedidos'];
      // console.log(this.historiaPedidos);

      for (let k of this.historiaPedidos) {
        k.myitemsdetalle = [];
        k.itemsreales = [];

        if (k.monto != 0) {
          for (let arta of k.items) {

            if (arta.precio < 0) {
              let imgenurl = new URL("/assets/logo/promo.png", this.environmenturl).toString();
              let articuloCopy = new ArticulosEcommerce
                (
                  arta.marca,
                  arta.codigo,
                  arta.precio,
                  arta.precio,
                  "Promociones y Descuentos",
                  0,
                  "",
                  0,
                  0,
                  0,
                  '0',
                  0,
                  0,
                  0,
                  '',
                  0,
                  0,
                  imgenurl,
                  "",
                  -1,
                  [],
                  false,
                  arta.cantidad,

                );
              //articuloCopy.setCantidad();
              //console.log(articuloCopy);
              //articuloCopy.setImgDownload(articuloCopy.getImgDownload());
              //articuloCopy.setFoto(articuloCopy.getFotoImagen());
              k.myitemsdetalle.push(articuloCopy);
              k.monto += articuloCopy.getPrecioCalculado();
              continue;

            }
            this.sucursalactiva.articulos.forEach(artreal => {

              if (arta.codigo == artreal.getCodigo() && arta.marca == artreal.getMarca()) {
                let articuloCopy = new ArticulosEcommerce
                  (
                    artreal.getMarca(),
                    artreal.getCodigo(),
                    arta.precio,
                    arta.precio,
                    artreal.getDescripCion(),
                    0,
                    artreal.getPresentacion(),
                    0,
                    0,
                    0,
                    '0',
                    0,
                    0,
                    0,
                    '',
                    0,
                    0,
                    artreal.getOtrosPrecios(),
                    artreal.getFotoImagen(),
                    -1,
                    [],
                    false,
                    arta.cantidad
                  );

                //articuloCopy.operacionesExtras();
                // artreal.getPromos()
                /*this.tienePromoArticulo(articuloCopy).forEach(k=>{
                 articuloCopy.setPromo(k);
                })*/


                //articuloCopy.setCantidad(arta.cantidad);
                //console.log(articuloCopy);
                if (artreal.getImgDownload()) {
                  articuloCopy.setImgDownload(artreal.getImgDownload());
                  articuloCopy.setFoto(artreal.getFotoImagen());
                }


                k.myitemsdetalle.push(articuloCopy);
                k.itemsreales.push(articuloCopy);
              }


            })
          }
        }
        else {
          // console.log("Monto === 0");

          this.historiaPedidos = this.historiaPedidos.filter((item) => item.monto !== 0);
        }
      }
      // console.log(this.historiaPedidos);

      this.historialtemp.next(this.historiaPedidos);
    }));


  }
  tienePromoArticulo(artreal: ArticulosGeneral): PromocionesEccomerce[] {
    let mispromos: PromocionesEccomerce[] = [];

    if (artreal.getPromos().length > 0) {
      return [];
    }
    this.promocionesEccomerce.forEach(promo => {
      if (promo.getCodigoA1() == artreal.getCodigo() && promo.getMarcaA1() == artreal.getMarca()) {
        mispromos.push(promo);
      }
      if (promo.getCodigoA2() == artreal.getCodigo() && promo.getMarcaA2() == artreal.getMarca()) {
        mispromos.push(promo);
      }
    })
    return mispromos;
  }
  getHistorialProvider() {
    return this.historialtemp.asObservable();
  }

  /**
   *  makes a generic get request without headers or security
   * @param params params for Get
   * @param ruta  Url to consult
   */
  //https
  getGenerico(params: HttpParams, ruta: string) {
    return this.httpClient.get(this.environmenturl.toString() + ruta, {
      headers: { "Cache-Control": "max-age=10800" }, params
    });
  }
  postGenerico(url: string, body: object) {
    return this.httpClient.post(this.environmenturl.toString() + url, body);
  }
  putGenerico(url: string, body: object) {
    return this.httpClient.put(this.environmenturl.toString() + url, body);
  }
  loginPost(url: string, body: object) {
    return this.httpClient.post(this.environment.endpoint + url, body);
  }
  private lastObs: string;
  private sucursalactiva: SucursalEcommerce;
  private sucursalactivatemp: BehaviorSubject<SucursalEcommerce>;
  private sucursal_defecto: string;
  private articulosSucursalDefecto: ArticulosGeneral[];

  private sucursales: SucursalEcommerce[];
  private sucursalestemp: BehaviorSubject<SucursalEcommerce[]>;
  private urlmap: string;

  private environment: { endpoint: string, endpointversion };
  private environmenturl: URL;

  private user: Usuario;
  private userProvider: BehaviorSubject<Usuario>;
  private lastSearch: BehaviorSubject<ArticulosGeneral[]>;
  private minisearch: BehaviorSubject<ArticulosGeneral[]>
  private paginadorProvider: BehaviorSubject<number>
  private paginadorProviderOfertas: BehaviorSubject<number>
  private promocionesEccomerce: PromocionesEccomerce[];
  private contador: number;
  private tareas: Observable<void>[];
  private intervals: number[];
  private idInterval: number;
  private categoriasWeb: CategoriasWebMenu[];
  private marcasDestacadas: MarcasEcommerce[];
  private articulosdelServidor: BackUptArticulo[];
  private carritoObservables: Observable<void>[];
  private idIntervalForCart: number;
  private reset: boolean;
  /*
  busqeuda
  */
  private resultadosDebusqueda: ArticulosGeneral[];
  private numBusqueda: number;
  private paginaActual: number;
  private paginaActualOfertas: number;
  private anchoPagina: number;
  private fueBusquedaEscrita: boolean;

  private remplasosProvider: BehaviorSubject<RemplazosMMax[]>;
  private remplazos: RemplazosMMax[];

  private mypedido: PedidosMmaxEccomerce;
  private mapaPedidos: Map<number, { marca: number, codigo: number }[]>;
  private resultadodeBusqueda: BehaviorSubject<ResultadosDeBusquedaInterface>

  private montoMinimo: number;
  private pagoDiferido: string;
  private envioHabilitado: string;
  private registroHabilitado: string;
  /*
  historial
  */
  private detalleElegido: HistorialPedidos;
  private historiaPedidos: HistorialPedidos[];
  private historialtemp: BehaviorSubject<HistorialPedidos[]>;
  /*
 ofertas
 */
  private ofertasEnlazadas: OfertasEcommerce[];
  private ofertasTemp: BehaviorSubject<OfertasEcommerce[]>;
  private ofertas: ArticulosGeneral[];
  /*
  manejo de pantallas...
  */
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  /*
  tags
  */
  private tags: TagsEcommerce[];
  private tagsRelacion: any[];
  private tagsActivos: Map<number, ArticulosGeneral[]>;
  /*
  mercadolibre
 */

  /*
   personalizacion;
  
  */
  private logoSvg: SafeUrl;
  private iconLogo: string;
  private nombreSitio: string;
  private colores: any;
  private min: number;
  private max: number;
  private oldSort: any;


}
