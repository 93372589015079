import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { SucursalEcommerce } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../../etc/categoria-checkbox.interface';
import { MenuMmaxInterface } from '../../etc/mmax-menu.interface';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'sucursales-mmax',
  templateUrl: './sucursalesmmax.component.html',
  styleUrls: ['./sucursalesmmax.component.scss']
})
export class SucursalesmmaxComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.serv.getproviderSucursales().subscribe(data => {
      let categorias: CheckboxSubcategoria[] = [];
      this.sucursalesPropias = data;
      data.forEach(k => {
        categorias.push({
          nombre: k.nombre,
          activada: false,
          id: k.sucursal.toString(),
        })
      })
      this.sucursales = { categorias: categorias, nombre: 'Sucursales' };
    });
  }

  volvermobile() {
    this.serv.navigator.goLandPage(this.router);
  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
    this.URLmap();
  }

  URLmap() {
    this.urlmap = this.serv.getURLMap();
    setTimeout(()=>{
      document.getElementById('map').innerHTML = this.urlmap;
    }, 50);
  };
  onSelect(event: CheckboxSubcategoria) {
    this.sucursalSelecionadaSelect = event;
    this.sucursalesPropias.forEach(k => {
      if (k.sucursal.toString() === event.id) {
        this.sucursalSelecionada = k;
        document.getElementById('map').innerHTML = this.sucursalSelecionada.urlmap;
      }
    })

  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get validScuru(): boolean {
    if (this.sucursalSelecionada == undefined) {
      return true
    }
    else {
      return false;
    }
  }
  async cambiarSucursal() {
    //Hacer todas las validaciones en caso de cambio
    if (this.sucursalSelecionada === undefined) {
      this.modal.open('modal-seleccione-suc');
    }
    else {
      for (let i = 0; i < this.menus.length; i++) {
        const element = this.menus[i];
        element.activo = false;
      }
      document.getElementById('map').innerHTML = this.sucursalSelecionada.urlmap;
      await this.serv.actualizarSucursal(this.sucursalSelecionada);
      this.serv.navigator.goLandPage(this.router);
      this.modal.close('sucursal-modal-1');

    }
  }
  /**/
  sucursalSelecionadaSelect: CheckboxSubcategoria;
  sucursales: CheckboxCategorias;
  sucursalesPropias: SucursalEcommerce[];
  sucursalSelecionada: SucursalEcommerce;
  urlmap: string;
}
