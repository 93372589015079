import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MarianomGeneral } from '../../marianom-general.service';
import { ModalService } from 'projects/app-shared/src/public-api';
import { SucursalEcommerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxSubcategoria } from '../../etc/categoria-checkbox.interface';


@Component({
  selector: 'login-form-mmax',
  templateUrl: './login-form-mmax.component.html',
  styleUrls: ['./login-form-mmax.component.scss']
})
export class LoginFormMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() registroHab: string;
  constructor(private formBuilder: FormBuilder, private modal: ModalService, private serv: MarianomGeneral, private router: ActivatedRoute) {

    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg'
    this.icogoogle = '../../assets/icons/google-logo.svg'
    this.icoface = '../../assets/icons/facebook-logo.svg'
    this.preImages = [this.imagen, this.icoface, this.icogoogle];
    this.modo = this.modo || 'normal';
    this.registroHab = this.registroHab || 'none';
    this.loginok = false;
  }
  salirMobile() {
    this.serv.navigator.goLandPage(this.router);
  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })

    setTimeout(() => {
      // console.log(this.serv.getRegistroHabilitado());
      
      if (this.serv.getRegistroHabilitado() === "S") {
        this.registroHab = 'flex';
      }
      else {
        this.registroHab = 'none';
      }

    }, 7000);
    
    
    this.preImages.forEach(async k => {
      new Image().src = k;
    });
    this.loginform = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

  }
  createUser() {
    this.modal.open('create-user-modal-1');
    this.modal.close('login-modal-1');
  }
  resetPass() {
    this.modal.open('send-reset-pass-modal-1');
    this.modal.close('login-modal-1');
  }
  cerrarModale(nombre) {
    this.modal.close(nombre);
  }

  submitForm() {

    this.serv.startLogin(this.formFields?.username.value, this.formFields?.password.value).subscribe(data => {

      this.loginok = true;
      this.serv.gotoURL('', this.router)
      this.serv.loginok();

    }, err => {
      this.loginform.setErrors({ incorrect: true })
      this.errorgen = err['error']['mensaje'];
      this.loginok = false;
    });

  }

  openModal() {
    setTimeout(() => {
      // console.log("LOGIN: ", this.loginok);
      
      if (this.loginok === true) {
        setTimeout(() => {
          this.modal.open('sucursal-modal-1');
        }, 1000);
  
      }
    }, 2000);
  }

  crearCuentea() {
    this.serv.navigator.goCrearAccount(this.router);
  }
  recuperarPassword() {
    this.serv.navigator.goResetPass(this.router);
  }
  get formFields() {
    return this.loginform.controls;
  }
  loginform: FormGroup;
  preImages: string[];
  imagen: string;
  icoface: string;
  icogoogle: string;
  errorgen: string;
  sucursalesPropias: SucursalEcommerce[];
  sucursalSelecionada: SucursalEcommerce;
  usuario: Usuario;
  loginok: boolean;

}
