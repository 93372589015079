import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ArticulosGeneral, HistorialPedidos } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-mmax-tracking-pedidos',
  templateUrl: './mmax-tracking-pedidos.component.html',
  styleUrls: ['./mmax-tracking-pedidos.component.scss']
})
export class MmaxTrackingPedidosComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this.iconreference = '../../assets/icons/checkpedidos.svg';
    this.calendaretiro = '../../assets/icons/date_range-24px-blue.svg';
    this.ubicaSuc = '../../assets/icons/location-pointer-blue.svg';
    this.horaretiro = '../../assets/icons/alarm-blue.svg';
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });

    window.addEventListener("beforeunload", function (e) {
          
      return window.location.hash = "#/";       
    
    });
  }

  get jistoria(): HistorialPedidos {
    return this.serv.getJistorial();
  }
  get articulos(): ArticulosGeneral[] {
    return this.jistoria.myitemsdetalle;
  }
  get turno() {
    let miturno = this.jistoria.turnos[0];
    miturno.mfecha = moment(miturno.fecha);
    miturno.mfecha.locale('es');
    miturno.dia = miturno.mfecha.format("dddd DD[/]MM");

    let hora: string = miturno.horainicio.toString();
    miturno.hora = '';
    if (hora.length == 3) {

      hora = '0' + hora;
    }
    for (let index = 0; index < 4; index++) {

      miturno.hora += hora.charAt(index) || '';
      if (index == 1) {
        miturno.hora += ':';
      }



    }
    // console.log(miturno);

    return miturno;
  }
  get sucursal() {
    let sucursal = this.jistoria.turnos[0]['sucursal'];

    if (sucursal) {

      return this.serv.getSucuralPorNumero(sucursal);
    }
    return '';
  }

  calendaretiro: string;
  horaretiro: string;
  ubicaSuc: string;
  iconreference: string;
}
