
<div *ngIf="modo==='normal'">
    <div *ngIf='!cargando; else elseBlock'>
        <div class="infobox infoboxred">
            <table class="table-turnos">
                <tr class="column">
                  <th class="filas centerfiles"></th>
                  <th *ngFor="let col of columnas" class="filas centerfiles">{{col}}</th>  
                </tr>
                <tr *ngFor="let turno of diasdisponibles">
                  <td class="filas" >{{turno.dia}}</td>
                  <td *ngFor="let turnin of turno.turnos;"  class="filas centerfiles">
                    
                    <div class="roundedOne">
                      <input [disabled]="!turnin.activado" [id]="turnin.id" type="checkbox" [checked]="turnin.selecionado" (change)="onchange(turnin)"/>
                      <label [for]="turnin.id"></label>
                    </div>
                   

                  </td>
                </tr>
               
              </table>
        
            


        </div>
    </div>
    <ng-template #elseBlock>

        <div class="infobox infoboxred">
          Recuperando Turnos...
        </div>

    </ng-template>
  
</div>