import { Component, ElementRef,Input, OnInit, Renderer2 } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'modal-open-point',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent implements OnInit {
  
  @Input() mode : string;
  @Input() id: string;
  constructor(private modalService: ModalService, private el: ElementRef , private render : Renderer2) 
  {
    this.mode= this.mode || 'normal'
    this.openModal=false;
  }
  
  ngOnInit(): void {
      // ensure id attribute exists
      if (!this.id) {
          console.error('el modal debe tener un id');
          return;
      }
     // console.log(this.mode);
      
      this.render.addClass(this.el.nativeElement,'notvisible'); 
      if (this.mode==='normal') {
        this.render.appendChild(document.body,this.el.nativeElement);
      } 
      this.modalService.add(this);
  }
  
   /* // close modal on background click
    @HostListener('click') onClick() {
        if (this.el.nativeElement.target.className === 'modal') {
            this.close();
        }
    }*/
    

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.remove()
      //this.el.nativeElement.remove();
  }
  remove(){
    this.modalService.remove(this.id);
    var parentnode = this.render.parentNode(this.el.nativeElement);
   //console.log(parentnode);
   this.render.removeChild(parentnode,this.el.nativeElement);
  }
  // open modal
  open(): void {
    
    this.render.addClass(this.el.nativeElement,'visible');    
    this.openModal=true;
  }

  // close modal
  close(): void {
    this.render.removeClass(this.el.nativeElement,'visible');    
    this.openModal=false;
    //this.remove();
  }
  get isOpen(){
    return this.openModal;
  }
  private openModal:boolean;

}
