<div *ngIf="modo==='normal'">
    <div class="franje">
        Marcas Destacadas
    </div>
    <section>
        <div class="bigbox">
            <div *ngFor="let marca of marcasD" class="box">
                <div class="imagebox" (click)="irAmarcas(marca)" onclick="window.scrollTo(0,0)">
                    <img class="image" [src]="marca.imagen" [alt]="marca.nombre">
                </div>
            </div>

        </div>
    </section>
    <mmax-helpfootbar></mmax-helpfootbar>

    <foo-mariano-max></foo-mariano-max>
</div>
<div *ngIf="modo==='pc-land'">
    <div class="marcas">
        <div class="marcacontainer">
            <div *ngFor="let marca of marcas" class="marca" (click)="irAmarcas(marca)" onclick="window.scrollTo(0,0)">
                <img class="image" [src]="marca.imagen" [alt]="marca.nombre">
            </div>
        </div>


    </div>

</div>