<!-- <modal-open-point id="turnos-mmax-modal-1">

    <mmax-turnos-retiro [mode]="'modal'" normal (turnoSelecionado)="turnoselect($event)"></mmax-turnos-retiro>
    <mmax-turnos-reparto [mode]="'modal'" normal (turnoSelecionado)="turnoselect($event)"></mmax-turnos-reparto>


</modal-open-point> -->

<modal-open-point id="confirmar-checkout-modal-1">
    <div normal>
        <confirmar-checkout-modal></confirmar-checkout-modal>
    </div>
</modal-open-point>
<div *ngIf="modo==='normal'">

    <div class="container">
        <div class="productos">
            <div class="productoslabel">
                Productos ({{total}})
            </div>
            <div class="listaprod">
                <lista-productos-view [articulosIn]="articulosconcantidad" [modelo]="'postcheckout'">
                </lista-productos-view>
            </div>
        </div>

        <div class="checkoutcontainer">
            <div class="productoslabel">
                Información Personal
            </div>
            <div class="infobox">
                <div class="info">
                    {{user.nombre}}
                </div>
                <div class="info">
                    {{user.email}}
                </div>
                <div class="info">
                    {{user.telefonos}}
                </div>
            </div>

            <div class="productoslabel separator">
                Envio o Retiro?
            </div>
            <div class="infobox">
                <div (click)="envio()" class="roundedOneE" id="envioHab" [ngStyle]="{'display': envioHab}"
                    style="flex-direction: row; display: flex; align-items: center; margin: 20px; margin-left: 30px;">
                    <input type="checkbox" [checked]="!displayEnvio" id="displayEnvio" />
                    <label style="margin-left: 20px; font-size: 22px;" for="displayEnvio">Envio</label>
                </div>
                <div (click)="retiro()" class="roundedOneR"
                    style="flex-direction: row; display: flex; align-items: center; margin: 20px; margin-left: 30px;">
                    <input type="checkbox" [checked]="!displayRetiro" id="displayRetiro" />
                    <label style="margin-left: 20px; font-size: 22px;" for="displayRetiro">Retiro</label>
                </div>
            </div>

            <div class="envioContainer" [ngStyle]="{'display': displayEnvio}">

                <div class="productoslabel separator" (click)="abrirCerrar">
                    Envio a Domicilio
                </div>
                <div class="infobox">
                    <app-envios-form></app-envios-form>
                </div>

                <div class="productoslabel separator ">
                    Elegí fecha y hora de entrega
                </div>
                <mmax-turnos-reparto (turnoSelecionado)="turnoselect($event)"></mmax-turnos-reparto>

                <div class="productoslabel separator">
                    Quién recibe el pedido?
                </div>
                <div class="infobox">
                    <div class="info infoblue">
                        {{user.nombre}}
                    </div>
                </div>

                <div class="productoslabel obstop">
                    Observaciones
                </div>
                <div class="boxtext">
                    <textarea [(ngModel)]="observacion" class="boxobs"
                        placeholder="Escribe aquí las aclaraciones sobre tu pedido…"></textarea>
                </div>

                <div class="total">
                    Total: {{totales | currency}}
                </div>
                <div class="botncontainer">
                    <div (click)="iraLand()" onclick="window.scrollTo(0,0)" class="noselect btons conticompra">
                        Anterior
                    </div>
                    <button [disabled]="turnoselecionado==undefined" (click)="modalCheckout()" onclick="window.scrollTo(0,0)"
                        class="noselect buttonpc blubutton">
                        Siguiente
                    </button>
                </div>
            </div>
            <div class="retiroContainer" [ngStyle]="{'display': displayRetiro}">

                <div class="productoslabel separator">
                    Sucursal de Retiro
                </div>
                <div class="infobox">
                    <div class="info infoblue">
                        {{sucusarl.nombre}} - {{sucusarl.barrio}}
                    </div>
                    <div class="info">
                        {{sucusarl.calle}} – {{sucusarl.localidad}}
                    </div>
                </div>

                <div class="productoslabel separator ">
                    Elegí fecha y hora de retiro
                </div>
                <mmax-turnos-retiro (turnoSelecionado)="turnoselect($event)"></mmax-turnos-retiro>

                <div class="productoslabel separator">
                    Quién retira el pedido?
                </div>
                <div class="infobox">
                    <div class="info infoblue">
                        {{user.nombre}}
                    </div>
                </div>

                <div class="productoslabel obstop">
                    Observaciones
                </div>
                <div class="boxtext">
                    <textarea [(ngModel)]="observacion" class="boxobs"
                        placeholder="Escribe aquí las aclaraciones sobre tu pedido…"></textarea>
                </div>

                <div class="total">
                    Total: {{totales | currency}}
                </div>
                <div class="botncontainer">
                    <div (click)="iraLand()" onclick="window.scrollTo(0,0)" class="noselect btons conticompra">
                        Anterior
                    </div>
                    <button [disabled]="turnoselecionado==undefined" (click)="modalCheckout()" onclick="window.scrollTo(0,0)"
                        class="noselect buttonpc blubutton">
                        Siguiente
                    </button>
                </div>
            </div>

        </div>

    </div>
    <mmax-helpfootbar></mmax-helpfootbar>