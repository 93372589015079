import { Component } from '@angular/core';
import { MarianomGeneral } from '../app-shared/marianom-general.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
})

export class AppComponent {
  constructor(private serv: MarianomGeneral) {

  }

  ngOnInit():void {
    this.serv.initMaster();
  }

  get nombre() {
    return this.serv.getNombreSitio();
  }
  get isClosed(): boolean {
    return this.serv.desplegado;
  }


}