<div *ngIf="modo==='normal'">
    <section>
        <div class="contendortablas">
                    
                <div class="tablas">
                        <div class="tabla noselect">
                            <div style="height:20px" class="header">
                                Institucional
                            </div>
                            <div class="sub">
                                Nuestra Empresa
                            </div>
                            <div class="sub">
                                Trabajá con nosotros
                            </div>
                            <div class="sub">
                                Proveedores
                            </div>
                        </div>
                </div>
                <div class="tablas">
                    <div class="tabla noselect">
                        <div style="height:20px" class="header">
                            Institucional
                        </div>
                        <div class="sub">
                            Nuestra Empresa
                        </div>
                        <div class="sub">
                            Trabajá con nosotros
                        </div>
                        <div class="sub">
                            Proveedores
                        </div>
                    </div>
            </div>
            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
        </div>
                <div class="tablas">
                    <div class="tabla noselect">
                        <div style="height:20px" class="header">
                            Institucional
                        </div>
                        <div class="sub">
                            Nuestra Empresa
                        </div>
                        <div class="sub">
                            Trabajá con nosotros
                        </div>
                        <div class="sub">
                            Proveedores
                        </div>
                    </div>
            </div>
            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
            </div>
    </div>
        <div class="socials">
            <div class="header">
                Necesitas Ayuda? | 0800-111-1111
            </div>
            <div class="header">
                Escribinos
            </div>
          
            <div class="socialIcons">
                <div class="icofacebook">
    
                </div>
                <div class="icoinsta">
                    
                </div>
                <div class="icoyoutube">
                    
                </div>
                <div class="icotwitter">
                    
                </div>
            </div>
        </div>
    </section>
</div>
