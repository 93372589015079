import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediosPagos } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mmax-cierre-carro',
  templateUrl: './mmax-cierre-carro.component.html',
  styleUrls: ['./mmax-cierre-carro.component.scss']
})
export class MmaxCierreCarroComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayMedios: string;
  @Input() displayPreMedios: string;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this.pagosActuales = [];
    this.mercadoP = false;
    this.pagoDiferido = this.serv.getPagoDiferido();
    this.displayMedios = this.displayMedios || 'none';
    this.ruta = window.location.hash;
    this.flagDeshabilitado = false;
  }


  ngOnInit(): void {
    // this.deshabilitarF5();
    window.addEventListener("beforeunload", function (e) {

      var confirmationMessage = "LOL";     
      e.returnValue = confirmationMessage;           
      return confirmationMessage;       
    
    });
    this.deshabilitarBack();
    /*
    this.serv.checkoutMercadoPago().then(data=>{
      console.log(data);
      const mp = new MercadoPago('APP_USR-14bf48c1-7b05-4932-a118-a914b6d467ab', {
        locale: 'es-AR'
      });
      mp.checkout({
        preference: {
            id: data['id']
        },
        render: {
              container: '.pagomercado', // Indica el nombre de la clase donde se mostrará el botón de pago
              label: 'Pagar', // Cambia el texto del botón de pago (opcional)
        }
      });
      
      
    });
    */
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
      // console.log(this.modo);
    });

    if (this.pagoDiferido == "S") {
      this.displayMedios = 'none';
      this.displayPreMedios = 'block';
    }

    if (this.pagoDiferido == "N") {
      this.displayMedios = 'block';
      this.displayPreMedios = 'none';
    }

  }

  deshabilitarBack() {

    // if (window.location.hash != "#/checkout/finalizar") {
    //   window.location.hash = "#/checkout/finalizar";
    // }

    window.onhashchange = function () {
      if (window.location.hash != "#/checkout/finalizar") {
        this.flagDeshabilitado = true;
        window.location.hash = "#/checkout/finalizar";
      }
      return this.flagDeshabilitado;
    }

    if (this.flagDeshabilitado === true) {
      this.serv.navigator.cierreCheckout(this.router);

    }
  }


  cancelarPedidoWeb() {

    // this.serv.cancelarPedidoWeb();
    this.serv.reobtenerHistorial();

  }

  volverAtras() {
    // history.go(-1);
    this.serv.navigator.goLandPage(this.router);
    this.serv.cerrarUI();
  }
  siPagar() {

    this.displayMedios = 'block';
    this.displayPreMedios = 'none';

  }

  noPagar() {
    this.serv.navigator.endline(this.router);
    this.serv.reseteccomerce();
  }

  activarMercadoPago() {
    this.serv.getMedios().forEach(k => {
      k.selecionado = false;
    })
    this.mercadoP = true;
  }
  get articulosconcantidad() {
    return this.serv.getCarrito();
  }
  activarPlan(plan: PagosMMaxPlanes) {
    this.pagosActuales.forEach(k => {

      if (plan != k) {
        k.seleccionado = false;
      }
    })
    plan.seleccionado = true;
    this.myPlan = plan;

  }
  activarMediodepago(medio: MediosPagos) {
    console.log(this.serv.getMedios());



    this.serv.getMedios().forEach(k => {
      k.selecionado = false;
    })
    this.mercadoP = false;
    medio.selecionado = true;

    this.mymedios = medio;

    this.serv.obtenerplanesdemedios(this.mymedios.mediopago).subscribe(data => {
      console.log(data);
      let planes = data['planes'] as PagosMMaxPlanes[];
      this.pagosActuales = [];
      if (planes != undefined) {
        planes.forEach(element => {
          this.pagosActuales.push(
            {
              cuotas: element?.cuotas,
              montoCuota: element?.montoCuota,
              nombre: element?.nombre,
              plan: element?.plan,
              id: this.serv.getId(5),
              seleccionado: false,
            }
          )
        });
        this.pagosActuales[0].seleccionado = true;
        this.myPlan = this.pagosActuales[0];
        this.myPlan.seleccionado = true
      }


    });

  }
  get mysmedios() {
    return this.serv.getMedios() || [];
  }
  get totales() {
    return this.serv.getTotal();
  }
  get total() {
    return this.serv.getCantidadTotal();
  }
  pagarYcontinuar() {
    this.error = undefined;
    this.serv.prayandpaidtwo(this.mymedios.mediopago, this.myPlan.plan).subscribe(data => {
      this.serv.navigator.endline(this.router);
      this.serv.reseteccomerce();
    }, err => {

      this.error = err['error']['statussalida'];
    })


  }
  error: string;
  pagosActuales: PagosMMaxPlanes[];
  mymedios: MediosPagos;
  myPlan: PagosMMaxPlanes;
  mercadoP: boolean;
  pagoDiferido: string;
  ruta: string;
  flagDeshabilitado: boolean;
}
declare var MercadoPago: any;

interface PagosMMaxPlanes {
  cuotas: number,
  montoCuota: number,
  nombre: string,
  plan: number,
  id?: string,
  seleccionado?: boolean,

}
