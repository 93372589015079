<div *ngIf="modo==='normal'">

    <section>
        <div class="container">

            <div class="titulo">
                Cuenta
            </div>
            <div class="fields">

                <div class="field">
                    <div class="campo texto">
                        Mail
                    </div>
                    <div class="valor texto">
                        {{user.email}}
                    </div>
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
                <div class="field">
                    <div class="campo texto">
                        Contraseña
                    </div>
                    <input [disabled]="true" type="password" value="contraseña" class="valor texto fieldpassword">
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
            </div>

            <div class="titulo">
                Datos Personales
            </div>
            <div class="fields">

                <div class="field">
                    <div class="campo texto">
                        Nombre Completo
                    </div>
                    <div class="valor texto">
                        {{user.nombre}}
                    </div>
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
                <div class="field">
                    <div class="campo texto">
                        DNI
                    </div>
                    <div class="valor texto">
                        {{user.dni}}
                    </div>
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
                <div class="field">
                    <div class="campo texto">
                        Dirección
                    </div>
                    <div class="valor texto">
                        {{user.direccion}}
                    </div>
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
                <div class="field">
                    <div class="campo texto">
                        Código Postal
                    </div>
                    <div class="valor texto">
                        {{''}}
                    </div>
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
                <div class="field">
                    <div class="campo texto">
                        Localidad
                    </div>
                    <div class="valor texto">
                        {{user.ciudad}}
                    </div>
                    <div class="accion ">
                        Modificar
                    </div>
                </div>
            </div>
        </div>


    </section>
    <!-- <mmax-helpfootbar [mode]="'flotante'"></mmax-helpfootbar> -->
    <mmax-helpfootbar></mmax-helpfootbar>

    <foo-mariano-max></foo-mariano-max>
</div>