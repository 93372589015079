import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-seleccione-suc',
  templateUrl: './modal-seleccione-suc.component.html',
  styleUrls: ['./modal-seleccione-suc.component.scss']
})
export class ModalSeleccioneSucComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar(){  
    this.modal.close('modal-seleccione-suc');
  }
}
