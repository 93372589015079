<div *ngIf="modo==='normal'">
    <div class="title">
        Compra {{jistoria.fecha}}
    </div>
    <div class="headcontainer extramargin">

        <estados-traking-mmax></estados-traking-mmax>


        <div class="infoextra">
            <div class="infotitle">
                Retirar:
            </div>
            <div class="info">
                <div class="icoinfo">
                    <img [src]="calendaretiro" alt="ok-step">
                </div>
                <div class="labelinfo">
                    {{turno.dia}}
                </div>
            </div>
            <div class="info">
                <div class="icoinfo">
                    <img [src]="horaretiro" alt="reloj">
                </div>
                <div class="labelinfo">
                    {{turno.hora}}
                </div>
            </div>
            <div class="info">
                <div class="icoinfo">
                    <img [src]="ubicaSuc" alt="ubicacion">
                </div>
                <div class="labelinfo">
                    {{sucursal}}
                </div>
            </div>
        </div>
    </div>
    <div class="articuloscontainer extramargin">
        <!--
            itemsreales
        -->
        <lista-productos-view [modelo]="'historialDetail'" [articulosIn]="articulos"></lista-productos-view>


        <div class="total">
            Total: {{jistoria.monto | currency}}
        </div>
        <div class="botncontainer">

            <div class="noselect btons conticompra">
                Ver Factura
            </div>
        </div>
    </div>

</div>