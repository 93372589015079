import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EcommerceGenericoService } from './ecommerce-generico.service';



@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ],
  exports: [],
  providers :[EcommerceGenericoService]
})
export class EcommerceGenericoModule { }
