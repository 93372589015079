import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'password-success',
  templateUrl: './mmaxreset-password-success.component.html',
  styleUrls: ['./mmaxreset-password-success.component.scss']
})
export class MmaxresetPasswordSuccessComponent implements OnInit {
  

  constructor() { 
    this.imagen='../../assets/misc/Illustración_CompraVIrutal.svg'
  }

  ngOnInit(): void {
  }

  imagen: string;
}
