import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { TagsEcommerce } from 'projects/ecommerce-generico/src/lib/interfaces/tags-eccomerce.interface';
import { ArticulosGeneral, CategoriasWebMenu, RemplazosMMax, ResultadosDeBusquedaInterface } from 'projects/ecommerce-generico/src/public-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';


@Component({
  selector: 'lista-productos-view',
  templateUrl: './lista-productos-view.component.html',
  styleUrls: ['./lista-productos-view.component.scss'],

})
export class ListaProductosViewComponent implements OnInit {
  @Input() articulosIn: ArticulosGeneral[]

  @Input() modelo: string;


  constructor(private serv: MarianomGeneral, private ref: ElementRef) {
    this.categoriasClickeables = [];
    this._destroyed$ = new Subject();
    this.nActCarrusel = this.nActCarrusel || 0;
    this.ofertaSlide = [];
    this.remplasos = [];
    this.misremplasos = [];
    this.numeropaginas = 0;
    this.min = 0;
    this.max = 0;
    this.initFiltros();
    this.serv.getPaginas().pipe(takeUntil(this._destroyed$)).subscribe(data => {

      // console.log(this.numeropaginas);
      this.numeropaginas = data;
      this.initFiltros();
      //this.elgirPrimerFiltro();
    });
    this.serv.getPaginasOfertas().pipe(takeUntil(this._destroyed$)).subscribe(data => {

      this.numeropaginas = data;
      // console.log("NUMERO PAGINAS DE OFERTAS: ", this.numeropaginas);

      // this.initFiltros();
    });
    this.serv.getRemplasos().pipe(takeUntil(this._destroyed$)).subscribe(data => {
      let subcat: CheckboxSubcategoria[] = [];
      this.misremplasos = data;
      this.misremplasos.forEach(k => {
        subcat.push({
          nombre: k.nombre,
          activada: false,
          id: k.modo
        });
      });
      this.remplasos.push({
        nombre: 'Reemplazo de productos',
        categorias: subcat
      });
    });


  }
  private async initFiltros() {
    let mayorPrecio: CheckboxSubcategoria = {
      nombre: 'Mayor Precio',
      activada: false,
      id: '1',
    }
    let menorPrecio: CheckboxSubcategoria = {
      nombre: 'Menor Precio',
      activada: false,
      id: '0'
    }
    let marcaaz: CheckboxSubcategoria = {
      nombre: 'Marca A-Z',
      activada: false,
      id: 'a'
    }
    let marcadecen: CheckboxSubcategoria = {
      nombre: 'Marca Z-A',
      activada: false,
      id: 'z'
    }
    this.gaseosas = [
      {
        nombre: 'Elegir Filtro',
        categorias: [mayorPrecio, menorPrecio, marcaaz, marcadecen]
      }
    ];

    this.serv.getResultadoExtras().pipe(takeUntil(this._destroyed$)).subscribe(data => {
      //console.log(data);
      this.categoriasClickeables = [];

      this.myExtrasResultado = data || {
        totaldeArticulos: 0,
        textodebusqueda: '',
      };
      if (this.myExtrasResultado.categoria == undefined) {
        this.categoriasClickeables.push({
          nombre: 'Busqueda',
          activable: false
        });
        this.categoriasClickeables.push({
          nombre: this.myExtrasResultado.textodebusqueda,
          activable: false
        });
      }
      else {
        this.categoriasClickeables.push({
          nombre: 'Busqueda',
          activable: false
        });
        this.categoriasClickeables.push({
          nombre: this.myExtrasResultado.categoria.nombre,
          activable: true,
          categoria: this.myExtrasResultado.categoria
        });
        this.myExtrasResultado.categoria.subCategorias.forEach(k => {
          k.subCategorias.forEach(tirdtier => {
            if (tirdtier.nombre === this.myExtrasResultado.textodebusqueda) {
              this.categoriasClickeables.push({
                nombre: k.nombre,
                activable: true,
                categoria: k,
              })
              this.categoriasClickeables.push({
                nombre: tirdtier.nombre,
                activable: true,
                categoria: tirdtier,
              })
            }
          })
        })
        //this.myExtrasResultado.categoria
      }


    });
  }
  clickOnCate(marca: CategoriasWebMenu, actiable: boolean) {
    if (actiable) {

      this.serv.busxMarcaCodigo(marca, this.myExtrasResultado.categoria);
    }

    //this.serv.navigator.goSearch(this.router);
  }
  categoriaSelec(event: CheckboxSubcategoria) {
    this.serv.marcarCarroConRemplazo(event)

  }
  selecionado(filtro: CheckboxSubcategoria) {
    this.gaseosas[0].categorias.forEach(k => {
      k.activada = false;
    });
    filtro.activada = true;
    this.serv.ordenarpor(filtro.id)
  }
  ngOnInit(): void {

    this.modelo = this.modelo || 'normal';
    let index = 0;
    this.articulos.forEach(k => {
      this.ofertaSlide.push({
        activado: false,
        id: this.serv.getId(3),
      })
      index++;
    })
    if (this.ofertaSlide.length > 0) {
      this.ofertaSlide[0].activado = true;
    }
    //console.log(this.remplasos);
    this.serv.marcarFaltantesConRemplazo(this.remplasos[0].categorias[0]);

    //console.log(this.articulosIn);


  }
  cerrarTag(tag: TagsEcommerce) {
    if (tag.selecionado) {
      tag.selecionado = false;
      this.serv.cerrartag(tag)
    }
    else {
      tag.selecionado = true;
      this.serv.ponerTag(tag)
    }

  }
  get tags() {
    let resultado: TagsEcommerce[] = [];
    if (this.myExtrasResultado.tagsEnbusqueda) {

    }
    this.myExtrasResultado?.tagsEnbusqueda?.forEach((k, v) => {
      resultado.push(k);
    })
    return resultado
  }

  get numBusqueda() {

    return this.serv.getNumBusqueda();
  }

  get fueBusquedaEscrita() {
    return this.serv.getFueBusquedaEscrita();
  }

  onPageSelect(event) {
    this.serv.cambiarDepagina(event);
    this.gaseosas[0].categorias.forEach(k => {
      if (k.activada) {
        this.selecionado(k);
      }
    })

  }
  onPageSelectOferta(event) {
    this.serv.cambiarDepaginaOferta(event);
  }

  get numOfertas() {
    return this.serv.getNumPagOfertas();
  }

  get articulos(): ArticulosGeneral[] {
    if (this.articulosIn == undefined) {
      return [];

    }
    else {
      return this.articulosIn;
    }
    // ;
  }

  private async desactivarOfertas(index: string) {
    let sape = 0;
    this.ofertaSlide.forEach(k => {
      if (k.id === index) {
        k.activado = true;
        this.nActCarrusel = sape;
      }
      else {
        k.activado = false;
      }
      sape++;
    });

  }
  get ofertas() {

    return this.serv.getOferta()
  };

  onPreviousClick() {
    // const previous = this.nActCarrusel - 1;
    // this.nActCarrusel = previous < 0 ? this.articulos.length - 1 : previous;
    // this.desactivarOfertas(this.ofertaSlide[this.nActCarrusel].id);

    const div = document.getElementById('slimarticulos');
    div.scrollLeft -= 327;

  }


  onNextClick() {
    // const next = this.nActCarrusel + 1;
    // this.nActCarrusel = next === this.articulos.length ? 0 : next;
    // this.desactivarOfertas(this.ofertaSlide[this.nActCarrusel].id);
    const div = document.getElementById('slimarticulos');
    div.scrollLeft += 327;

  }
  onchange(item: OfertaSlide) {
    this.desactivarOfertas(item.id);

  }

  anchoPag() {
    let ancho = document.getElementById("articulos");
    console.log(ancho.clientWidth);

    this.serv.getAnchoPagina(ancho.clientWidth);
  }

  precioMin() {
    this.min = parseInt((<HTMLInputElement>document.getElementById("precioMin")).value);
    
  }

  precioMax() {
    this.max = parseInt((<HTMLInputElement>document.getElementById("precioMax")).value);
    
  }
  

  rangoPrecio(){
    if (this.min > this.max) {
      let max = document.getElementById('precioMax');
      let min = document.getElementById('precioMin');
      max.style.borderColor = 'red';
      max.style.borderWidth = 'medium';
      min.style.borderColor = 'red';
      min.style.borderWidth = 'medium';
      
    } else {
      let max = document.getElementById('precioMax');
      let min = document.getElementById('precioMin');
      max.style.borderColor = 'none';
      max.style.borderWidth = 'revert';
      min.style.borderColor = 'none';
      min.style.borderWidth = 'revert';
      this.serv.getMinPrecio(this.min);
      this.serv.getMaxPrecio(this.max);
      this.serv.filtrarRangoPrecio();
    }
  }

  nActCarrusel: number;
  ofertaSlide: OfertaSlide[];
  numeropaginas: number;
  misremplasos: RemplazosMMax[];
  remplasos: CheckboxCategorias[];
  gaseosas: CheckboxCategorias[];
  myExtrasResultado: ResultadosDeBusquedaInterface;
  categoriasClickeables: CategoriaClickeable[];
  min: number;
  max: number;
  private _destroyed$: Subject<any>;
}

interface OfertaSlide {
  activado: boolean,
  id: string,
}
interface CategoriaClickeable {
  nombre: string,
  categoria?: CategoriasWebMenu,
  activable: boolean;
}