import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarianomGeneral } from '../marianom-general.service';
import { HistorialPedidos } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'historial-mmax-compras',
  templateUrl: './historial-mmax-compras.component.html',
  styleUrls: ['./historial-mmax-compras.component.scss']
})
export class HistorialMMaxComprasComponent implements OnInit {
  @Input() modo: string;
  @Input() loader: string;
  @Input() noCompras: string;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {

    this.modo = this.modo || 'normal';
    this.loader = this.loader || 'none';
    this.noCompras = this.noCompras || 'none';
    this.serv.getPedidosHistorial().subscribe(data => {
      // console.log(data.length);

      if (data.length === 0) {
        this.loader = 'block';
        this.noCompras = 'block';
      }
      else {
        this.loader = 'none';
        this.noCompras = 'none';
      }

      this.historial = this.serv.ordenarHistorialPorFecha(data);

    });
  }

  async ngOnInit() {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    // this.serv.llamarSpinner();
    // // this.loader = 'block';
    // setTimeout(()=>{
    //   this.serv.cerrarSpinner();
    //   // this.loader = 'none';
    // }, 5000);
    
  }

  recompra(jistoriaRecompra: HistorialPedidos) {
    this.serv.cargarEstePedidoEnCart(jistoriaRecompra);
    this.serv.navigator.irResumenCompra(this.router)
  }
  verDetalle(detalle: HistorialPedidos) {
    this.serv.pedidoAVer(detalle);
    this.serv.navigator.goDetallePedido(this.router);
  }
  historial: HistorialPedidos[];
}
