<div *ngIf="modo==='normal'">

    <div class="buublesinfo noselect">

        <div *ngFor="let ico of icons" class="bubble" (click)="goToTuto()">
            <div class="bubblediv">
                <img class="bubbleico" [src]="ico.src">
            </div>
            <div class="bubletext">
                {{ico.label}}
            </div>

        </div>
    </div>
</div>