import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-alert-pass',
  templateUrl: './modal-alert-pass.component.html',
  styleUrls: ['./modal-alert-pass.component.scss']
})
export class ModalAlertPassComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar(){
    this.modal.close('modal-alert-pass');
  }

}
