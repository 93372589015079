<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetavaciar">
                    <div class="titulo">
                        Ocurrió un error inesperado!
                    </div>
                    <div class="leyendavaciar">
                        Por favor, recargue la página y vea si el error se solucionó...
                        <br>
                        <br>
                         Si ya lo hizo y el error persiste comuniquese con el proveedor. Posiblemente la página esté caída.
                    </div>
            </div>
    </modal-default-mmaxapp>
</div>
