import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';

@Component({
  selector: 'app-help-bar-mmax',
  templateUrl: './help-bar-mmax.component.html',
  styleUrls: ['./help-bar-mmax.component.scss']
})
export class HelpBarMmaxComponent implements OnInit {

  constructor(private serv : MarianomGeneral, private router : ActivatedRoute, private modal: ModalService) { 
    this.ruta = window.location.hash;
  }

  ngOnInit(): void {
    // console.log(this.ruta);
    // console.log(window.location);
    
    /*
    this.router.data.subscribe(data => {
     
     });
     */
  }
  irAyuda(){
    this.ruta = window.location.hash;
    // console.log(window.location);

    if (this.ruta === '#/checkout/finalizar') {
      this.modal.open('modal-completar-pago');
    } else {
      this.serv.gotoURL('checkout/ayuda',this.router);
    }
  }
  volver(){
    //console.log(this.router);
    
    this.serv.navigator.goLandPage(this.router);
    //this.serv.reseteccomerce();
  }

  get logosrc(){
    return this.serv.getLogo();
  }

  iraLand(){
    this.ruta = window.location.hash;
    if (this.ruta === '#/checkout/finalizar') {
      this.modal.open('modal-completar-pago');

    } else {      
      this.serv.navigator.goLandPage(this.router);
      this.serv.cerrarUI();
    }
  }


  ruta: string;
}
