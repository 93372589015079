import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'send-reset-pass',
  templateUrl: './send-reset-pass.component.html',
  styleUrls: ['./send-reset-pass.component.scss']
})
export class SendResetPassComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;
  @Input() form: string;

  constructor(private formBuilder: FormBuilder, private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg'
    this.modo = this.modo || 'normal';
    // setTimeout(() => {
    //   var contenedor = document.getElementById("container-carga");
    //   contenedor.style.visibility = "hidden";
    //   contenedor.style.opacity = "0";
    // }, 1);
    this.cambiOK = true;

  }
  salirMobile() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.router);
  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    new Image().src = this.imagen;
    this.resetform = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
    // console.log(this.resetform);
    this.loader = this.loader || 'none';
    this.form = this.form || 'grid';
  }
  get formFields() {
    return this.resetform.controls;
  }

  async cambiarPass() {
    this.form = 'none';
    this.loader = 'flex';
    
    await this.serv.pedirCambioPass(this.formFields.email.value).toPromise().catch(err => {
      this.cambiOK = false;
      this.form = 'grid';
      this.loader = 'none';
      this.modal.open('modal-alert-pass');
      console.log(err);
      this.errorForm = err.error.statussalida;
      console.error(err['statusText']);
      // this.createForm.reset();
    });

    if (this.cambiOK === true) {
      this.serv.email = this.formFields.email.value;
      this.modal.close('send-reset-pass-modal-1');
      this.modal.open('reset-pass-modal-1');
      
    } 
    
  }

  imagen: string;
  resetform: FormGroup;
  createForm: FormGroup;
  errorForm: string;
  cambiOK: boolean;

}
