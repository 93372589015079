
<section>
    <div class="centro">
        <div class="texto">
            <div class="mastexto">
                Reestablecer Contraseña
            </div>
            <div class="subtexto">
                Hemos enviado un mail a nomb….@gmail.com con un enlace para reactivar tu cuenta. Te esperamos aquí.
            </div>
        </div>
        <div class="dibugopart">
            
            <div class="imagecontainer">
                <img [src]="imagen" class="imagen">
            </div>
        
        </div>
    </div>

</section>