import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SucursalEcommerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { PedidosMmaxEccomerce } from 'projects/ecommerce-generico/src/public-api';
import { timeStamp } from 'console';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'app-mmax-checkout-compra',
  templateUrl: './mmax-checkout-compra.component.html',
  styleUrls: ['./mmax-checkout-compra.component.scss']
})
export class MmaxCheckoutCompraComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayEnvio: string;
  @Input() displayRetiro: string;
  @Input() envioHab: string;
  constructor(private router: ActivatedRoute, private serv: MarianomGeneral, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.displayEnvio = this.displayEnvio || 'none';
    this.displayRetiro = this.displayRetiro || 'none';
    this.envioHab = this.envioHab || 'none';
    this.desplegado = false;
    this.serv.getUserProvider().subscribe(data => {

      this.user = data;

    });
    this.serv.getSucursalActivada().subscribe(sucs => {
      // console.log(sucs);
      this.sucusarl = sucs;
    });
    this.envioHabilitado = this.serv.getEnvioHabilitado();
    this.pagoDiferido = this.serv.getPagoDiferido();
  }

  ngOnInit(): void {
    this.observacion = undefined;
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

    if (this.envioHabilitado === "S") {
      this.envioHab = 'flex'
    }
    else {
      this.envioHab = 'none';
    }
  }

  get articulosconcantidad() {
    return this.serv.getCarrito();
  }

  get totales() {
    return this.serv.getTotal();
  }
  get total() {
    return this.serv.getCantidadTotal();
  }

  turnoselect(turnoselect) {
    this.turnoselecionado = turnoselect;
    this.serv.setTurno(this.turnoselecionado);
  }

  envio() {
    if (this.desplegado = false) {
      this.displayEnvio = 'none';
      this.desplegado = true;
    }
    if (this.desplegado = true) {
      this.displayEnvio = 'block';
      // let checkEnvio = document.getElementById("roundedOneE input[type=checkbox]:checked + label:after") as HTMLInputElement
      // checkEnvio.setAttribute("style", "opacity: 1");

      this.displayRetiro = 'none';
      this.desplegado = false;
      // let checkRetiro = document.getElementById("roundedOneR input[type=checkbox]:checked + label:after") as HTMLInputElement;

      // if (checkRetiro.checked) {
      //   // checkRetiro.setAttribute("style", "opacity: 1")        
      // }
    }
  }

  retiro() {
    if (this.desplegado = false) {
      this.displayRetiro = 'none';
      this.desplegado = true;
    }
    if (this.desplegado = true) {
      this.displayRetiro = 'block';
      // let checkRetiro = document.getElementById("roundedOneR input[type=checkbox]:checked + label:after") as HTMLInputElement
      // checkRetiro.setAttribute("style", "opacity: 1");

      this.displayEnvio = 'none';
      this.desplegado = false;
      // let checkEnvio = document.getElementById("roundedOneE input[type=checkbox]:checked + label:after") as HTMLInputElement;

      // if (checkEnvio.checked) {
      //   // checkEnvio.setAttribute("style", "opacity: 1")
      // }
    }
  }

  abrirTurnos() {
    this.serv.abrirTurnos()
  }
  cerrarPedido() {
    this.serv.setTurno(this.turnoselecionado);
    this.observaCion();
    this.serv.borrarPromoCiones().subscribe((data) => {

      this.serv.cerrarPromociones().subscribe((data) => {
        
        this.serv.prayandpaid().subscribe(data => {
          let pedido = data['pedido'] as PedidosMmaxEccomerce;
          // console.log(pedido);
          this.serv.guardarDatosDePagos(pedido);
          this.serv.reobtenerHistorial();
          if (this.pagoDiferido === "E") {
            this.serv.navigator.endline(this.router);
            this.serv.reseteccomerce();
          }
          else{
            this.serv.navigator.cierreCheckout(this.router);
          }

        });
      });


    });


  }
  observaCion() {
    if (this.observacion) {
      this.serv.setObServa(this.observacion)
    }

  }
  cerrarPedidoMobile() {
    this.observaCion();
    this.serv.setTurno(this.turnoselecionado);
    this.serv.navigator.cierreCheckout(this.router)

  }
  iraLand() {
    this.serv.navigator.irResumenCompra(this.router)
    this.serv.cerrarUI();
  }

  modalCheckout(){
    this.modal.open('confirmar-checkout-modal-1');
  }


  observacion: string;
  turnoselecionado: TurnosRetiroEccomerce;
  user: Usuario;
  sucusarl: SucursalEcommerce;
  desplegado: boolean;
  envioHabilitado: string;
  pagoDiferido: string;
}
