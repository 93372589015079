import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'foo-mariano-max',
  templateUrl: './foo-mariano-max.component.html',
  styleUrls: ['./foo-mariano-max.component.scss']
})
export class FooMarianoMaxComponent implements OnInit {
  @Input('mode')modo:string;
  constructor() {
    this.modo = this.modo || 'normal';
   }

  ngOnInit(): void {
  }

}
