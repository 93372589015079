<div FollowBar class="topbar">
    <div class="container">
        <div class="logo" (click)="iraLand()" onclick="window.scrollTo(0,0)">
            <img  [src]="logosrc" alt="Logo">
        </div>
        <div (click)="irAyuda()" onclick="window.scrollTo(0,0)" class="yuda quicka noselect">
            Ayuda
        </div>
        
      
    </div>
</div>
<div class="normalicedscreen">
    <router-outlet></router-outlet>
</div>
