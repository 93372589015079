<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                    <div class="titulo">
                          Funcion no implementada
                    </div>
                    <div class="leyendaconfirmar">
                            Esta funcion aun no ha sido implementada.
                            <br>
                            <br>
                            Por favor, intente probarla en otro momento.
                            <br>
                            Por cualquier duda o reclamo, comuniquese con el proveedor. Gracias!
                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                    </div>
            </div>
    </modal-default-mmaxapp>
</div>
