<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                    <div class="titulo">
                           Su contraseña no pudo ser cambiada.
                    </div>
                    <div class="leyendaconfirmar">
                            Hubo un error inesperado al intentar mandar el mail o cambiar su contraseña.
                            <br>
                            <br>
                            Por favor asegurese que los datos esten bien ingresados e intentelo mas tarde.
                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                    </div>
            </div>
    </modal-default-mmaxapp>
</div>
