
<div *ngIf="mode==='normal'">
    <div class="modal">
        <div>
            <ng-content select="[normal]">
                
            </ng-content>
        </div>
    </div>
    <div class="background-modal"></div>
</div>

<div *ngIf="mode==='split'">
    <div class="slideactiva">
        <ng-content select="[split]"></ng-content>
    </div>
   
</div>
