import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ImagesInterface } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'carrusel-generico-view',
  templateUrl: './carrusel-generic-view.component.html',
  styleUrls: ['./carrusel-generic-view.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ],
})
export class CarruselGenericViewComponent implements OnInit {
  @Input() slides: ImagesInterface[];
  @Input() mode: string;

  constructor() {
    this.mode = this.mode || 'normal';
    this.currentSlide = 0;
    this.slidePosition = [];
  }
  onswipeleft() {
    //console.log('swipe left');

    this.onPreviousClick();
  }
  onswipeRight() {
    this.onNextClick()
  }
  onPreviousClick() {
    const previous = this.currentSlide - 1;

    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    this.onchange(this.slidePosition[this.currentSlide]);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    this.onchange(this.slidePosition[this.currentSlide]);
  }
  ngOnInit() {
    this.preloadImages(); // for the demo
  }

  preloadImages() {
    let id = 0;
    for (const slide of this.slides) {
      new Image().src = slide.src;
      this.slidePosition.push({
        activado: false,
        id: '' + id,
      });
      id++;
    }
    //console.log(this.slidePosition);
    this.slidePosition[0].activado = true;
  }
  onchange(item: SlidePosition) {
    item.activado = true;
    this.currentSlide = Number.parseInt(item.id);
    this.slidePosition.forEach(k => {
      if (item.id !== k.id) {
        k.activado = false;
      }

    })

  }
  currentSlide: number;
  slidePosition: SlidePosition[]
}

interface SlidePosition {
  activado: boolean,
  id: string,
}
