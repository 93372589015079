<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                    <div class="titulo">
                           Contraseñas no identicas.
                    </div>
                    <div class="leyendaconfirmar">
                            Las contraseñas que ingresaste no son identicas.
                            <br>
                            <br>
                            Por favor asegurese de que los datos sean bien ingresados.
                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                    </div>
            </div>
    </modal-default-mmaxapp>
</div>
