import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[FollowBar]'
})
export class FollowBarDirective implements OnInit {

  constructor(private el: ElementRef,private render : Renderer2) { 
    //
    
  }
  ngOnInit(): void {
    this.render.setStyle(this.el.nativeElement,'position','sticky');
    this.render.setStyle(this.el.nativeElement,'top','0');
    this.render.setStyle(this.el.nativeElement,'z-index','99');
    this.render.setStyle(this.el.nativeElement,'width','100%');
 
  }

}
