import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'vaciar-mobile-mmax-modal',
  templateUrl: './vaciar-mobile-mmax-modal.component.html',
  styleUrls: ['./vaciar-mobile-mmax-modal.component.scss']
})
export class VaciarMobileMmaxModalComponent implements OnInit {

  constructor(private serv: MarianomGeneral,private modal : ModalService) { }

  ngOnInit(): void {
  }
  vaciar(){
    this.modal.closeAll();
    this.serv.vaciarCarro();
  }
  cancelar(){
    this.modal.closeAll();
  }
}
