import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MarianomGeneral } from '../marianom-general.service';

@Injectable()
export class NotLoginMmaxGuard implements CanActivate {
  constructor(private serv :MarianomGeneral){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
    return this.hasUser();
  }
  hasUser( ) : Observable<boolean>
  {
   
    return new Observable<boolean>(obs =>{
     // obs.next(true);
     
      this.serv.getUserProvider().subscribe(data=>{
       //abrir modal.. con el erro de no logearse
        
        
        if (data==undefined) {
          obs.next(false);
         // this.serv.navigator.goerror()
         this.serv.navigator.notLogin();
        }
        else{
          obs.next(true);
        }

    });


    })
  }
}