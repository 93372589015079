import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { PedidosMmaxEccomerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'confirmar-checkout-modal',
  templateUrl: './confirmar-checkout-modal.component.html',
  styleUrls: ['./confirmar-checkout-modal.component.scss']
})
export class ConfirmarCheckoutModalComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.pagoDiferido = this.serv.getPagoDiferido();
  }

  ngOnInit(): void {
  }

  observaCion() {
    if (this.observacion) {
      this.serv.setObServa(this.observacion)
    }

  }

  ultimoTurno() {
    this.turnoselecionado = this.serv.ultimoTurno();
  }

  cerrarPedido() {
    this.ultimoTurno();
    this.serv.setTurno(this.turnoselecionado);
    this.observaCion();
    this.modal.close('confirmar-checkout-modal-1');
    this.serv.borrarPromoCiones().subscribe((data) => {
      
      this.serv.cerrarPromociones().subscribe((data) => {
        
        this.serv.prayandpaid().subscribe(data => {
          let pedido = data['pedido'] as PedidosMmaxEccomerce;
          this.serv.guardarDatosDePagos(pedido);
          this.serv.reobtenerHistorial();
          if (this.pagoDiferido === "E") {
            this.serv.navigator.endline(this.router);
            this.serv.reseteccomerce();
          }
          else{
            this.serv.navigator.cierreCheckout(this.router);
          }
        });
      });
    });
  };

  cancelar(){
    this.modal.close('confirmar-checkout-modal-1')
  }


  observacion: string;
  turnoselecionado: TurnosRetiroEccomerce;
  envioHabilitado: string;
  pagoDiferido: string;
}
