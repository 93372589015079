<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                    <div class="titulo">
                            Agregue productos.
                    </div>
                    <div class="leyendaconfirmar">
                            No puede realizar esta accion ya que no dispone de productos agregados en el carrito.
                            <br>
                            <br>
                            Por favor, agregue productos para continuar.
                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                    </div>
            </div>
    </modal-default-mmaxapp>
</div>
