import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ArticulosGeneral } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { ActivatedRoute } from '@angular/router';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';


@Component({
  selector: 'searchview-generic',
  templateUrl: './search-view-generic.component.html',
  styleUrls: ['./search-view-generic.component.scss'],
  animations: [GenericoSlideAnim]
})
export class SearchViewGenericComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private route: ActivatedRoute) {

    this.arrowsrc = '../../assets/icons/arrow-left-blue.svg';
    this.busquedaActual = "";
    this.currentSlide = -1;
    this.toggle = false;
  }

  initSearch() {


  }
  onChange(texto: string) {// Input
    if (texto.length > 1) {

      this.currentSlide = -1;
      this.serv.abriryCerrrarSearch(true);
      this.serv.inciarbusqueda(this.busquedaActual);
      this.serv.ordenarpor('a');

    }


  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }
  onclick() {
    this.toggle = !this.toggle;
    this.serv.abriryCerrrarSearch(this.toggle);

  }

  oncloseGap(event) {
    //console.log(event);
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.abriryCerrrarSearch(false);
  }
  selecionadoItem(item: ArticulosGeneral) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.busquedaActual = item.getDescripCion();
    this.serv.inciarbusqueda(this.busquedaActual);
    this.serv.navigator.goSearch(this.route);

  }


  ngOnInit(): void {
    new Image().src = this.arrowsrc;
    this.serv.getMinisearch().subscribe(data => {
      this.resultados = data;
    });
    this.modo = this.modo || 'normal';

  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    //console.log(event.key);
    if (event.key === "ArrowDown") {
      if ((this.currentSlide + 1) < this.resultados.length) {
        this.currentSlide += 1;
      }

    }
    if (event.key === "ArrowUp") {
      //i === currentSlide
      if ((this.currentSlide - 1) >= -1) {
        this.currentSlide -= 1;
      }
    }
    if (event.key === "Enter") {
      //i === currentSlide
      if (this.resultados[this.currentSlide] != undefined) {
        this.selecionadoItem(this.resultados[this.currentSlide]);
        this.serv.abriryCerrrarSearch(false);
      }
      else {
        for (let i = 0; i < this.menus.length; i++) {
          const element = this.menus[i];
          element.activo = false;
        }
        this.serv.inciarbusqueda(this.busquedaActual);
        this.serv.navigator.goSearch(this.route);
        //this.selecionadoItem(this.resultados[this.currentSlide]);
        this.serv.abriryCerrrarSearch(false);
        this.serv.ordenarpor('a');

      }
    }


  }
  volveratras() {
    this.serv.navigator.goLandPage(this.route);
  }
  volvermobile() {
    this.serv.navigator.goLandPage(this.route);
  }
  get desplegado() {
    return this.serv.desplegadosearch;
  }

  arrowsrc: string;
  resultados: ArticulosGeneral[]
  busquedaActual: string;
  currentSlide: number;
  toggle: boolean;
}
