<div class="caja">
    <div class="leftb">
        <div class="labels noselect">

            <div [ngClass]="{'pagado': jistoria.estado==='Pagado'}" class="estado spacelete">
                {{jistoria.estado}}
            </div>
            <div class="fechad spacelete">
                Compra {{jistoria.fecha}}
            </div>
            <div (click)="verDetalle()" class="verdetalle">
                <div class="plus">
                    Ver Detalle
                </div>
                <div class="icon">

                </div>
            </div>

        </div>
        <div class="productos">
            <div *ngFor="let art of jistoria.itemsreales" class="productocontainer">
                <img class="product" [src]="art.getFotoImagen()" loading="lazy">
            </div>

            <!--
            <div class="contadorcontainer">
                <div class="contador">
                    +13
                </div>
            </div>
           -->




        </div>

    </div>
    <div class="lefta">
        <div class="precio">
            {{jistoria.monto | currency}}
        </div>
        <div (click)="repetirCompra()" class="btncontainer noselect">
            <div class="botncont">
                Repetir Compra
            </div>
        </div>
        <div (click)="pagar()" class="btncontainer noselect" [ngStyle]="{'display': displayPagoDiferido}">
            <div class="botncont">
                Pagar
            </div>
        </div>
    </div>



</div>