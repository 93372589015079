import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-completar-pago',
  templateUrl: './modal-completar-pago.component.html',
  styleUrls: ['./modal-completar-pago.component.scss']
})
export class ModalCompletarPagoComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar(){
    this.modal.close('modal-completar-pago')
  }

}
