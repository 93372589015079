
<div *ngIf="modo==='normal'">
    
    <div class="container">
        <div class="busqueda">
            <div class="spacer"></div>  
              <input [(ngModel)]="busquedaActual" (input)="onChange($event.target.value)" onkeypress="if(event.keyCode === 13){window.scrollTo(0, 0);}" (click)="onclick()" class="mysearch" placeholder="Buscar, producto, marca" type="text">
            <div class="search noselect" (click)="onclick()" onclick="window.scrollTo(0, 0)"></div>
        </div>
    
        <div [@openClose]="desplegado ? 'open' : 'closed'" class="extendible">
           <div class="predictions">
           <productos-view (closeGap)="oncloseGap($event)"  *ngFor="let item of resultados; index as i" (click)="selecionadoItem(item)" onclick="window.scrollTo(0, 0)" [ngClass]="currentSlide==i ? 'seleccionado':''"  [articulo]="item" [mode]="'buqueda'"></productos-view>
           </div>
        </div>
    </div>
</div>