import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'mmax-input-selector',
  templateUrl: './mmax-input-selector.component.html',
  styleUrls: ['./mmax-input-selector.component.scss']
})
export class MmaxInputSelectorComponent implements OnInit {
  @Input() opciones: RemplazosMMax[];
  @Input() item: RemplazosMMax;
  @Output() onSelect: EventEmitter<any>;

  constructor() {
    this.onSelect = new EventEmitter();
  }

  ngOnInit(): void {
  }
  onchange(event: RemplazosMMax) {
    this.opciones.forEach(k => {
      k.activo = false;
    })
    event.activo = true;
  }

}
