import { OfertasEcommerce } from "../interfaces/ofertas-ecommerce.interface";
import { TagsEcommerce } from "../interfaces/tags-eccomerce.interface";
import { PromocionesEccomerce } from "./promociones-class";

export class MontoPromo extends PromocionesEccomerce{
   deOferta: OfertasEcommerce;
   isdeoferta: boolean;


    setTag(tag: TagsEcommerce) {
        throw new Error("Method not implemented.");
    }
    getTag(): TagsEcommerce[] {
        throw new Error("Method not implemented.");
    }
    
    getCantidad() {
        return this.counter;
    }
    getRemplazos() {
        throw new Error("Method not implemented.");
    }
    isdeOferta(): boolean {
        return false;
    }
    setdeOferta(ofertas: OfertasEcommerce) {
        throw new Error("Method not implemented.");
    }
    getPromosActivas(): string[] {
        return [];
    }

    getDescontadoTotal(): number {
        return this.descontado;
    }
    getDescontado(): number {
        return this.monto;
    }
    preconPromo(precio: number,cantidad:number,modificacionActual:number) {
        let preciototal = precio * cantidad;
       // preciototal-=modificacionActual;
        let arestar = preciototal - this.monto;
        this.descontado += arestar;
        return -arestar;
    }
    operacionesExtras() {
        console.log('se ejecuto MontoPromo');
    }
    getPrecioCalculado(): number {
        return 0;
    }
    getPrecioMayorista(){
        return 0;
    }

    getPrecioOferta(): number {
        return this.getPrecioOferta();
    }
   
    setMyBlob(body: Blob) {
        this.myblob=body;
    }
    getMyBlob(): Blob {
        return this.myblob;
    }
    setRemplazo(numero: number) {
        throw new Error("Method not implemented.");
    }
    getOtrosPrecios(): string {
        throw new Error("Method not implemented.");
    }
    getPrecioPartido(): number {
        throw new Error("Method not implemented.");
    }
    getImgDownload(): boolean {
        return this.descargo;
     }
     setImgDownload(valor: boolean) {
         this.descargo=valor;
     }
     setPromo(k: PromocionesEccomerce) {
        throw new Error("Method not implemented.");
    }
   
    getPrecioNormal() {
        return 0;
    }
    getPrecioAmostrar() {
        return 0;
    }
    getPresentacion() {
        return '';
    }
    agregarCantidad(numero: number) {
        console.log(' se agrego monto promo');
    }
    setCantidad(arg0: number) {
        console.log(' se seeteo monto promo');
    }
    
    getPrecio() {
        let cuenta = 0;
       
            if(this.esDoblePromo()) {
                cuenta = ((this.articulo1.getPrecio())+(this.articulo2.getPrecio()*this.articulo2.getCantidad()))-this.monto ;   
                
            }
            else{
                if (this.articuloenPromo().getCantidad()>0) {
                    cuenta = (this.articuloenPromo().getPrecio() *this.articuloenPromo().getCantidad())-this.monto * this.counter; 
                }
               
            } 
           // console.log(cuenta*1);
            
            return (cuenta /this.counter)*-1;
    }
    
    getRemplazo() {
        throw new Error("Method not implemented.");
    }
   
    
    hacerCalculos() {
        let cuenta = 0;
        /*
            if(this.esDoblePromo()) {
                if ((this.articulo1.getCantidad()>0) && (this.articulo2.getCantidad()>0) ) {
                    cuenta = this.monto - (this.articulo1.getPrecio()+this.articulo2.getPrecio()); 
                }   
                
            }
            else{
                if (this.articuloenPromo().getCantidad()>0) {
                    cuenta = this.monto - (this.articuloenPromo().getPrecio()); 
                }
               
            } 
                */
            if(this.esDoblePromo()) {
                cuenta = this.monto*this.counter  - ((this.articulo1.getPrecio())+(this.articulo2.getPrecio()*this.articulo2.getCantidad()));   
                
            }
            else{
                if (this.articuloenPromo().getCantidad()>0) {
                    cuenta = this.monto*this.counter  - (this.articuloenPromo().getPrecio() *this.articuloenPromo().getCantidad()); 
                }
               
            } 
           // console.log(cuenta*1);
            
            return cuenta;
    }
    setModificacionPrecio(arg0: number) {
        throw new Error("Method not implemented.");
    }
    private myblob:Blob;
 
}