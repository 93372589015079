import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosEcommerce, ArticulosGeneral } from 'projects/ecommerce-generico/src/public-api';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mmax-search-results',
  templateUrl: './mmax-search-results.component.html',
  styleUrls: ['./mmax-search-results.component.scss']
})
export class MmaxSearchResultsComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() myData: string;
  @Input() login: string;
  menues: { nombre: string; url: string; urlmobile: string; icourl: string; }[];
  constructor(private serv: MarianomGeneral, private route: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal'
    this.resultadosDeLaBusqueda = [];
    this.serv.getLastSearchProvider().subscribe(data => {
      this.resultadosDeLaBusqueda = data;
      let fecha = new Date();
      let newArticulos = [];
      for (let i = 0; i < this.resultadosDeLaBusqueda.length; i++) {
        const element = this.resultadosDeLaBusqueda[i];
        if (element.isdeoferta === true && element.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
        
          newArticulos.push(element);
  
        }
        if (element.isdeoferta === false) {
          newArticulos.push(element);
        }
      }
      
      this.resultadosDeLaBusqueda = newArticulos;      

    })

    this.menues = [
      {
        nombre: 'Mis Datos',
        url: 'app/misdatos',
        urlmobile: '/app-mobile/app/account',
        icourl: '../../assets/icons/credit-card-outline-white.svg'
      }
    ];

    this.swicher = false;
    this.isLogued = this.serv.isloged();
    this.myData = this.myData || 'none';
    this.login = this.login || 'none';
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });
    if (this.isLogued === false) {
      this.myData = 'none';
      this.login = 'block';
    } else {
      this.myData = 'flex';
      this.login = 'none';
    }
  }

  openLogin() {
    this.serv.gotoURL('', this.route);
    setTimeout(() => {
      this.modal.open('login-modal-1');
    }, 10);
  }

  cerrarSesion() {
    this.serv.gotoURL('', this.route);
    setTimeout(() => {
      this.serv.salirylimpiar();
    }, 10);

  }


  getColor() {
    if (this.swicher) {
      this.swicher = !this.swicher;
      return 'rgb(214 220 246 / 13%)';
    }
    else {
      this.swicher = !this.swicher;
      return '#FFFFFFFF';
    }
  }

  gotoURL(url: string) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.gotoURL(url, this.route);

  }

  menuclick(menus: MenuMmaxInterface) {
    this.serv.activatethis(menus);

    // console.log(menus);

    // console.log(this.menus);
    this.serv.gotoURL('' + menus.ruta, this.route);

  }

  get categorias() {
    return this.serv.getCategorias();
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  resultadosDeLaBusqueda: ArticulosGeneral[];
  swicher: boolean;
  isLogued: boolean;
}
