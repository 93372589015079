<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                    <div class="titulo">
                           Seleccione una sucursal.
                    </div>
                    <div class="leyendaconfirmar">
                            Por favor, seleccione una sucursal para continuar.
                    </div>
                    <div class="botones">
                            <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                    </div>
            </div>
    </modal-default-mmaxapp>
</div>
