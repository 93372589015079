import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmax-landpage-frame',
  templateUrl: './mmax-landpage-frame.component.html',
  styleUrls: ['./mmax-landpage-frame.component.scss']
})
export class MmaxLandpageFrameComponent implements OnInit {
  @Input() mode: string;
  @Input() nombre: string;
  constructor() {
    this.mode = this.mode || 'normal'
  }

  ngOnInit(): void {
  }

}
