import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';

import { RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-resumen-comprammax',
  templateUrl: './resumen-comprammax.component.html',
  styleUrls: ['./resumen-comprammax.component.scss']
})
export class ResumenComprammaxComponent implements OnInit {
  @Input('mode') modo: string;


  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.arrowsrc = '../../assets/icons/arrow-left-white.svg';
    this.remplasos = [];
    this.montoMinimo = 0;
  }
  categoriaSelec(event: CheckboxSubcategoria) {
    this.serv.marcarCarroConRemplazo(event)

  }
  volverAtras() {
    this.serv.navigator.goLandPage(this.router);
  }
  preguntarVaciar() {
    this.modal.open('vaciar-carro-modal-3');
  }
  volverATras() {
    this.serv.navigator.goLandPage(this.router);
  }
  irAchekout() {
    this.serv.navigator.precierreCheckout(this.router);

  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });
    this.serv.getRemplasos().subscribe(data => {
      this.misremplasos = data;
      let subcat: CheckboxSubcategoria[] = [];
      //console.log(data);
      this.misremplasos.forEach(k => {
        subcat.push({
          nombre: k.nombre,
          activada: false,
          id: k.modo
        });
      });
      this.remplasos.push({
        nombre: 'Reemplazo',
        categorias: subcat
      });


    });
    // this.seleccionMasiva(this.remplasos[0].categorias[0].)
    this.montoMinimo = this.serv.getMontoMinimo()

  }
  get articulosconcantidad() {
    return this.serv.getCarrito();
  }

  get totales() {
    return this.serv.getTotal();
  }
  goCheckoutPc() {
    let total = this.serv.getTotal();
    
    if (total < this.montoMinimo) {
      this.modal.open("modal-monto-minimo");
    }
    else{
      this.serv.navigator.precierreCheckout(this.router);
    }
  }

  misremplasos: RemplazosMMax[];
  arrowsrc: string;
  remplasos: CheckboxCategorias[];
  montoMinimo: number;
}
