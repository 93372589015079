import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'vaciar-carrito-modal-mmax',
  templateUrl: './vaciar-carrito-modal-mmax.component.html',
  styleUrls: ['./vaciar-carrito-modal-mmax.component.scss']
})
export class VaciarCarritoModalMmaxComponent implements OnInit {

  constructor(private serv: MarianomGeneral,private modal : ModalService) { }

  ngOnInit(): void {
  }
  vaciar(){
    this.modal.closeAll();
    this.serv.vaciarCarro();
  }
  cancelar(){
    this.modal.closeAll();
  }
   
}
