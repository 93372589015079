import { ArticulosGeneral } from "../class/articulos-general.interface";
import { PromocionesEccomerce } from "../class/promociones-class";
import { OfertasEcommerce } from "./ofertas-ecommerce.interface";
import { RemplazosMMax } from "./remplazos-ecommerce.interface";
import { TagsEcommerce } from "./tags-eccomerce.interface";

export class ArticulosEcommerce extends ArticulosGeneral {

    constructor(
        marca: number,
        codigo: number,
        private precio: number,
        private precionormal: number,
        public descripcion: string,
        private unidadesxb: number,
        private presentacion: string,
        private pesoxb: number,
        private pesable: number,
        private pesableporunidad: number,
        private nombremarca: string,
        private cantidad: number,
        private coeficiente: number,
        private preciopor: number,
        private unidaddmedida: string,
        private cantidadmayorista: number,
        private preciomayorista: number,
        private etiquetamedida: string,
        private foto: string,
        private remplazo: number,
        private remplazos: RemplazosMMax[],
        //emulado
        private frecuente?: boolean,
        private cantiadadpedido?: number,

    ) {
        super(marca, codigo);
        this.cantiadadpedido = this.cantiadadpedido || 0;
        this.misPromo = [];
        this.cantidadInicial = 0;
        this.descargoimagen = false;
        this.modificacionPrecio = 0;
        this.precioAyuda = this.precio;
        this.promoCiones = [];
        this.isdeoferta = false;
        this.tags = [];
        this.isdePromo = false;

    }

    setTag(tag: TagsEcommerce) {
        this.tags.push(tag);
    }
    getTag(): TagsEcommerce[] {
        return this.tags;
    }
    setMyBlob(body: Blob) {
        this.myBlob = body;
    }
    getMyBlob(): Blob {
        return this.myBlob;
    }
    getImgDownload(): boolean {
        return this.descargoimagen;
    }

    setImgDownload(valor: boolean) {
        this.descargoimagen = valor;
    }
    setdeOferta(ofertas: OfertasEcommerce) {
        //this.precio= ofertas.precio;
        this.isdeoferta = true;
        this.deOferta = ofertas;

    }
    isdeOferta(): boolean {
        return this.isdeoferta;
    }
    getPrecioCalculado(): number {

        // if (this.isdeoferta === true) {
        //     if (this.isdePromo === true) {
        //         return this.precioOferta + -this.deOferta.precio;
        //     } else {
        //         return (this.precio * this.cantiadadpedido) + this.modificacionPrecio;
        //     }
        // }
        
        return (this.precio * this.cantiadadpedido) + this.modificacionPrecio;

    }

    getPrecioOferta(): number {
        return this.deOferta.precio * 1;
    }

    async operacionesExtras() {

        let numeros: number[] = [];
        this.promoCiones = [];
        let descripciones: Map<number, string> = new Map();
        this.modificacionPrecio = 0;
        this.precioOferta = 0;
        this.isdePromo = false;

        if (this.isdeOferta()) {
            //console.log(this);

            this.precioOferta = this.deOferta.precio * this.cantiadadpedido;
            //console.log("precio de oferta : "+ precioOferta);
            numeros.push(this.precioOferta);
            // console.log("\n\nPush numeros O: ", this.precioOferta);

            descripciones.set(this.precioOferta, " Oferta");

        }
        if (this.misPromo.length > 0) {
            this.promoCiones = [];
            this.modificacionPrecio = 0;
            let cantidadRestantePromo = this.cantiadadpedido;//7
            this.misPromo.forEach(k => {
                k.counter = 0;
                let cantidadpromo = k.cantidadenPromo();
                //console.log(cantidadpromo);

                if (cantidadRestantePromo >= cantidadpromo) {// tiene la cantidad necesaria
                    let veces = cantidadRestantePromo / cantidadpromo;
                    veces = Math.floor(veces);
                    //console.log(' cantidad de veces : ' +veces );
                    if (veces >= 1) {
                        // this.promoCiones.push(k.getDescripCion());
                        descripciones.set(0, k.getDescripCion())
                    }
                    for (let index = 0; index < veces; index++) {
                        k.counter++;
                        cantidadRestantePromo -= cantidadpromo;
                        this.aplicarPromo(cantidadpromo, k);
                    }

                }

                else {
                    //     console.log('no tiene cantidad necesaria para ' + k.getDescripCion());
                }
            });


            let promo = descripciones.get(0);
            descripciones.delete(0);
            descripciones.set(this.getPrecioCalculado(), promo);

            // if (this.isdePromo === true) {
            //     numeros.push(this.getPrecio() * this.cantiadadpedido);
            //     console.log("Push numeros CP: ", this.getPrecio() * this.cantiadadpedido);

            // } else {
            //     numeros.push(this.getPrecioCalculado());
            //     console.log("Push numeros C: ", this.getPrecioCalculado());

            // }

            numeros.push(this.getPrecioCalculado());
            // console.log("Push numeros C: ", this.getPrecioCalculado());

            //return precioModificado;
        }

        if (this.cantidadmayorista != 0) {
            if (this.cantiadadpedido >= this.cantidadmayorista) {
                if (this.preciomayorista != 0) {
                    // this.precio=this.preciomayorista;
                    let precioMayorista = this.preciomayorista * this.cantiadadpedido;
                    //console.log("precio de quiebre : "+ precioMayorista);
                    descripciones.set(precioMayorista, " Llevando " + this.cantidadmayorista + " o más pagas " + this.preciomayorista + " por c/d");
                    numeros.push(precioMayorista);
                }
                else {
                    // this.precio=this.precioAyuda;
                }
                //console.log(this.cantidadmayorista);


            }
            else {

            }
        }

        numeros.sort(this.sortFun);

        if (numeros.length > 0 && (numeros[0] != (this.getPrecio() * this.cantiadadpedido))) {

            let amodif = (this.getPrecio() * this.cantiadadpedido) - numeros[0];                
            // console.log("Numeros: ", numeros);
            // console.log("Precio (" + this.getPrecio() + ") x Cantidad (" + this.cantiadadpedido + "): ", this.getPrecio() * this.cantiadadpedido);
            // console.log("Resta: (" + this.getPrecio() * this.cantiadadpedido + ") - (" + numeros[0] + ") = " + amodif);

            this.modificacionPrecio = 0;
            this.modificacionPrecio += -amodif;
            // console.log("Modificacion Precio: ", this.modificacionPrecio);

            this.promoCiones = [];
            descripciones.forEach((k, v) => {
                this.promoCiones.push(k)
            })

        }
    }

    sortFun = (a: number, b: number): number => {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    }
    private aplicarPromo(cantidadpromo: number, k: PromocionesEccomerce) {
        console.log("Promo");

        this.isdePromo = true;

        // if (this.isdeoferta === true) {
        //     console.log("OFERTA");

        //     this.modificacionPrecio += k.preconPromo(this.deOferta.precio, cantidadpromo, this.modificacionPrecio);
        // } else {
        //     console.log("NO OFERTA");

        // }
        this.modificacionPrecio += k.preconPromo(this.precio, cantidadpromo, this.modificacionPrecio);
    }
    getPromosActivas(): string[] {
        return this.promoCiones;
    }
    resetPrecios() {
        this.cantiadadpedido = 0;
        this.modificacionPrecio = 0;
    }
    getOtrosPrecios(): string {
        return this.etiquetamedida;
    }
    getPrecioPartido(): number {
        return this.preciopor;
    }
    setFoto(arg0: string) {
        this.foto = arg0;
    }
    getFotoImagen() {
        return this.foto;
    }
    getPrecio() { //articulos
        return this.precio;

    }
    getPromos() {
        return this.misPromo;
    }

    setPromo(k: PromocionesEccomerce) {
        if (k != undefined) {
            this.misPromo.push(k);
        }
    }

    getCantidad() {
        return this.cantiadadpedido;
    }
    getNombreMarca() {
        return this.nombremarca;
    }
    getDescripCion() {
        return this.descripcion;
    }
    getPresentacion() {
        return this.presentacion;
    }
    sumarCantPromo(arg0: number) {
        this.cantiadadpedido += arg0;
    }
    agregarCantidad(numero: number) {
        this.cantiadadpedido += numero;
    }

    setCantidad(arg0: number) {
        this.cantiadadpedido = arg0;
    }
    getPrecioNormal() {
        return this.precionormal;
    }
    getPrecioMayorista() {
        return this.preciomayorista;
    }
    getPrecioAmostrar() {
        return this.precio;
    }
    forzarAddPromo(v: number) {
        throw new Error("Method not implemented.");
    }

    getTipoPromo() {
        throw new Error("Method not implemented.");
    }

    setCantidadInicial(v: number) {
        this.cantidadInicial = v;
    }
    getCantidadInicial() {
        return this.cantidadInicial;
    }
    getRemplazos() {
        return this.remplazos;
    }
    getRemplazo() {
        return this.remplazo;
    }
    setRemplazo(numero) {
        this.remplazo = numero;
    }
    setModificacionPrecio(arg0: number) {
        this.modificacionPrecio = arg0;
    }

    private misPromo: PromocionesEccomerce[];
    private modificacionPrecio: number;
    private cantidadInicial: number;
    private myBlob: Blob;
    private descargoimagen: boolean;
    private precioAyuda: number;
    promoCiones: string[];
    isdeoferta: boolean;
    private isdePromo: boolean;
    deOferta: OfertasEcommerce;
    private tags: TagsEcommerce[];
    precioOferta: number;

}