import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriasWebMenu } from 'projects/ecommerce-generico/src/public-api';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'categ-viewgeneric',
  templateUrl: './categ-viewgeneric.component.html',
  styleUrls: ['./categ-viewgeneric.component.scss'],
  animations: [GenericoSlideAnim]
})
export class CategViewgenericComponent implements OnInit {
  @Input() categoria: CategoriasWebMenu;
  @Input() color: string;
  @Input() mode: string;
  @Output() categoriasEvento: EventEmitter<CategoriasWebMenu>;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.desplegado = false;
    this.desplegado2 = false;
    this.color = this.color || 'none';
    this.categoriasEvento = new EventEmitter();
  }

  ngOnInit(): void {
  }

  get categorias() {
    return this.serv.getCategorias();
  }

  abrirCerrar() {
    this.serv.desplegaryCerrar(!this.desplegadoCat);
  }

  get desplegadoCat(): boolean {
    return this.serv.desplegadocat;
  }

  onDeplegar() {
    if (this.categoria.desplegado === true) {
      this.categoria.desplegado = !this.categoria.desplegado;
      this.categoriasEvento.emit(this.categoria);
      this.desplegado = true;
      // console.log(this.desplegado);

    }
    else {
      for (let i = 0; i < this.categorias.length; i++) {
        const element = this.categorias[i];
        element.desplegado = false;
        element.subCategorias.forEach(k => {
          k.desplegado = false;
        })
      }
      this.categoria.desplegado = !this.categoria.desplegado;
      // console.log(this.categoria);
      this.categoriasEvento.emit(this.categoria);
      this.desplegado = false;
      // console.log(this.desplegado);

    }

  }
  onDeplegar2() {
    this.desplegado2 = !this.desplegado2;
  }

  onDeplegarsub(sub: CategoriasWebMenu) {
    sub.desplegado = !sub.desplegado;
  }
  onDeplegarsubtwo(sub: CategoriasWebMenu) {
    this.categoria.subCategorias.forEach(k => {
      if (sub != k) {
        k.desplegado = false;
      }

    })
    sub.desplegado = !sub.desplegado;
  }
  activartirdcat(tercercat: CategoriasWebMenu, segundacat: CategoriasWebMenu) {
    segundacat.subCategorias.forEach(element => {
      element.desplegado = false;
    });
    tercercat.desplegado = true;
  }

  irAmarcas(marca: CategoriasWebMenu) {

    if (this.desplegado === false) {

      this.serv.busxMarcaCodigo(marca, this.categoria);
      this.serv.navigator.goSearch(this.router);
    }

  }
  irAcategorias(marca: CategoriasWebMenu) {

    if (this.desplegado === false) {

      this.serv.busquedaCategorias(marca, this.categoria);
      this.serv.navigator.goSearch(this.router);
    }

  }

  irMarcasMobile(marca: CategoriasWebMenu) {
    this.serv.busxMarcaCodigo(marca, this.categoria);
    this.serv.navigator.goSearch(this.router);

  }
  desplegado: boolean;
  desplegado2: boolean;


}