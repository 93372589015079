/*
 * Public API Surface of app-shared
 */
/*
export * from './lib/app-shared.service';
export * from './lib/app-shared.component';
*/
export * from './lib/modal-utils/modal.service';
export * from './lib/modal-utils/modal/modal.component'
export * from './lib/app-shared.module';
