<modal-open-point id="modal-monto-minimo">
    <div normal>
        <modal-monto-minimo></modal-monto-minimo>
    </div>
</modal-open-point>
<modal-open-point id="vaciar-carro-modal-3">
    <div normal>
        <vaciar-carrito-modal-mmax></vaciar-carrito-modal-mmax>
    </div>
</modal-open-point>

<div *ngIf="modo==='normal'">
    <div class="informer">
        <div class="titulogra">
            Carrito de compras
        </div>
        <div class="titulop">
            Información sobre las políticas de reemplazo
        </div>
    
        
    </div>
    <section>
        <div class="container">
           <lista-productos-view [articulosIn]="articulosconcantidad" [modelo]="'resumendetail'"></lista-productos-view>
           <div class="total">
            Total: {{totales | currency}}
           </div>
           <div class="botncontainer">
               <div (click)="preguntarVaciar()" class="noselect btons vaciar">
                Vaciar carrito
               </div>
               <div (click)="volverAtras()" onclick="window.scrollTo(0,0)" class="noselect btons conticompra">
                Seguir comprando
               </div>
               <div (click)="goCheckoutPc()" onclick="window.scrollTo(0,0)" class="noselect btons comprar">
                Comprar
               </div>
           </div>
       
        </div>
        
    </section>
    <div class="foo">
        <mmax-helpfootbar></mmax-helpfootbar>
    
        <foo-mariano-max></foo-mariano-max>

    </div>
</div>
