<div class="barcontainer">
    <div class="topbarcontainer">
        <div class="logo noselect" (click)="iraLand()" onclick="window.scrollTo(0,0)">
            <img [src]="logosrc" class="logoimg" alt="logo">
        </div>
        <div class="menuses noselect">

            <div [@openClose]="menus.activo  ? 'open' : 'closed'" [routerLink]="menus.ruta" *ngFor="let menus of menus"
                (click)="menuclick(menus)" onclick="window.scrollTo(0,0)" class="menu">
                {{menus.nombre || 'No Disponible'}}
            </div>
        </div>
        <div class="usuario">
            <div class="containermini">
                <mmax-usuario-budget></mmax-usuario-budget>
            </div>

        </div>
        <div class="categi-mobile leftmargin noselect">
            <categorias-generico></categorias-generico>
        </div>
        <div class="carrita-mobile">
            <carrito-generico></carrito-generico>
        </div>
    </div>
    <div FollowBar class="topbarlowcontainer">

        <div *ngIf="scroll" class="logo noselect" (click)="iraLand()" onclick="window.scrollTo(0,0)">
            <img [src]="logosrc" class="logomini" alt="mmax-logo">
        </div>
        <div class="categi leftmargin noselect">
            <categorias-generico></categorias-generico>
        </div>
        <div class="logo noselect" (click)="iraLand()" onclick="window.scrollTo(0,0)"></div>
        <div class="contenedorsearch">
            <searchview-generic></searchview-generic>

        </div>

        <div class="carrita">
            <carrito-generico></carrito-generico>
        </div>
    </div>
    <div class="store-topbarcontainer">
        <span class="store-text" style="margin: 0 auto">Retirar en {{ nombreSucursal}} </span>
    </div>
</div>